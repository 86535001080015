import { Restaurant } from 'src/app/models/restaurant';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigStyles } from 'src/app/models/config';

@Injectable({ providedIn: 'root' })
export class ConfigStore {
  private _restaurant = new BehaviorSubject<Restaurant | null>(null);
  private _config = new BehaviorSubject<ConfigStyles>({} as ConfigStyles); // config store will be initialized with default config styles
  config$ = this._config.asObservable();
  restaurant$ = this._restaurant.asObservable();
  restaurantConfig: ConfigStyles | null = null;

  constructor() {
    if (localStorage.getItem('restaurant')) {
      const localRestaurant = JSON.parse(
        localStorage.getItem('restaurant') || '{}'
      );
      const restaurant: Restaurant = localRestaurant as Restaurant;
      this.dispatchSetRestaurantData(restaurant);
      this.overrideRestaurantStyle(restaurant);
    }
  }

  dispatchInitConfig(config: ConfigStyles | null): void {
    config = config || this.default;
    this._config.next(config);
    // save also into localstorage
    localStorage.setItem('config_styles', JSON.stringify(config));
  }

  dispatchSetConfigStyleFromLocalStorage(config: ConfigStyles): void {
    this._config.next(config);
  }

  dispatchSetRestaurantData(data: Restaurant): void {
    this._restaurant.next(data);
    localStorage.setItem('restaurant', JSON.stringify(data));
  }

  overrideRestaurantStyle(restaurantConfig: Restaurant | null): void {
    const colorStylesConfig =
      restaurantConfig?.configuration?.template_style || null;
    if (colorStylesConfig) {
      const htmlEl = document.querySelector('html');
      if (colorStylesConfig.color_primary) {
        htmlEl?.style.setProperty(
          '--ion-color-primary',
          colorStylesConfig.color_primary
        );
      }
      if (colorStylesConfig.color_primary_contrast) {
        htmlEl?.style.setProperty(
          '--ion-color-primary-contrast',
          colorStylesConfig.color_primary_contrast
        );
      }
      if (colorStylesConfig.color_primary_contrast_rgb) {
        htmlEl?.style.setProperty(
          '--ion-color-primary-contrast-rgb',
          colorStylesConfig.color_primary_contrast_rgb
        );
      }
      if (colorStylesConfig.color_primary_shade) {
        htmlEl?.style.setProperty(
          '--ion-color-primary-shade',
          colorStylesConfig.color_primary_shade
        );
      }
      if (colorStylesConfig.color_primary_tint) {
        htmlEl?.style.setProperty(
          '--ion-color-primary-tint',
          colorStylesConfig.color_primary_tint
        );
      }
    }
  }

  get restaurant(): Restaurant | null {
    return this._restaurant.getValue();
  }

  get config(): ConfigStyles {
    return this._config.getValue();
  }

  get default(): ConfigStyles {
    console.log('Default configuration activated');
    return {
      // PRE LOAD PAGE
      l4pay_preloading_bkg: '/assets/img/4pay_preloading_bkg.png',
      l4pay_menu_order: '/assets/icon/4Pay_icon_mainmenu_order.svg',
      l4pay_labware_logo: '/assets/icon/labware_logo.svg',
      l4pay_logo: '/assets/icon/4Pay_logo.svg',
      // HOME
      restaurant_logo: '/assets/icon/restaurant_logo.png',
      order_pay: '/assets/icon/4Pay_icon_mainmenu_view_and_pay.svg',
      order_new: '/assets/icon/4Pay_icon_mainmenu_order.svg',
      menu_icon: '/assets/icon/4Pay_icon_mainmenu_menu.svg',
      footer_banner: '/assets/img/promo_offer.jpg',
      order_modal: 'assets/img/4order-menu-popup.jpg',
      //CHECKOUT
      tip: '/assets/icon/4Pay_icon_add_tip.svg',
      edit_tip: '/assets/icon/4Pay_icon_edit_tip_euro.svg',
      //RECEIPT
      email: '/assets/icon/4Pay_icon_send.svg',
      email_active: '/assets/icon/4Pay_icon_send_valid.svg',
      hide_items: '/assets/icon/4Pay_icon_hide.svg',
      invoice: '/assets/icon/4Pay_icon_invoice_euro.svg',
      invoice_active: '/assets/icon/4Pay_icon_invoice_euro_active.svg',
      //PAYMENT
      payment_card: '/assets/icon/4Pay_icon_payment_card.svg',
      payment_payxpert: '/assets/icon/payxpert.png',
      //PAYMENT FEEDBACK
      receipt: '/assets/icon/4Pay_icon_share.svg',
      receiptDOC: '/assets/RECEIPT.pdf',
      //SURVEY
      good: '/assets/icon/4Pay_icon_experience_positive.svg',
      medium: '/assets/icon/4Pay_icon_experience_neutral.svg',
      bad: '/assets/icon/4Pay_icon_experience_negative.svg',
      good_checked: '/assets/icon/experience_good_checked_icon.png',
      medium_checked: '/assets/icon/experience_medium_checked_icon.png',
      bad_checked: '/assets/icon/experience_bad_checked_icon.png',
      //INFO-MODAL
      person_pay: '/assets/img/person_pay.jpg',
      code_punto_cassa: '/assets/icon/code_punto_cassa.svg',
      pagamento_sicuro: '/assets/icon/pagamento_sicuro.svg',
      scontrino: '/assets/icon/scontrino.svg',
      //STYLES
      // styles: {}
      styles: {
        home: '/assets/home.css',
        order: '/assets/order.css',
        checkout: '/assets/checkout.css',
        receipt: '/assets/receipt.css',
        payment: '/assets/payment.css',
        stripe: '/assets/stripe.css',
        survey: '/assets/survey.css',
      },
    };
  }
}
