import { CheckoutStore } from './checkout.store';
import { Checkout } from 'src/app/models/checkout';
import { Country } from './../../../assets/data/country';
import { StripeConfigFR, StripeConfigIT } from 'src/app/models/config';
import {
  CustomerType,
  PopupoverStatus,
  InvoiceFR,
  InvoiceIT,
} from './../../models/invoice';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable jsdoc/newline-after-description */
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, take, tap } from 'rxjs/operators';
import { Receipt } from 'src/app/models/receipt';
import { InvoiceErrorFieldItem } from 'src/app/models/invoice-error-field-item';
import { ConfigStore } from './config.store';
import { Restaurant } from 'src/app/models/restaurant';
import { OrderItem } from 'src/app/models/order-item';

@Injectable({ providedIn: 'root' })
export class ReceiptStore {
  customerType: string | null = null;
  stripeConfig: StripeConfigIT | StripeConfigFR;
  countrySelected: Country;
  vatCodeLabel = 'invoice_form_france_vat_code'; // translations key: you can find the value in 'src/assets/data/translations.ts'
  private _invoiceFR: Partial<InvoiceFR> | null = null;
  private _invoiceIT: Partial<InvoiceIT> | null = null;
  // private _invoice$ = new BehaviorSubject<Partial<Invoice>>({ country: environment.country });
  private _invoiceFR$ = new BehaviorSubject<Partial<InvoiceFR>>({
    country: 'FR - France',
  }); // defualt FR - France
  private _invoiceIT$ = new BehaviorSubject<Partial<InvoiceIT>>({
    country: 'IT - Italia',
  });
  private _property = new BehaviorSubject<Record<string, string> | null>(null);
  private _invoice_04 = new BehaviorSubject<InvoiceErrorFieldItem[] | null>(
    null
  );
  private customerPopover = new BehaviorSubject<PopupoverStatus>('close');
  invoice_04_fields$ = this._invoice_04.asObservable();
  customerPopover$ = this.customerPopover.asObservable();
  configStore$: Observable<Restaurant | null> = this.configStore.restaurant$;

  invoiceFR$ = combineLatest([this._invoiceFR$, this._property]).pipe(
    map(([invoice, property]) =>
      property ? { ...invoice, ...property } : { ...invoice }
    )
    // tap(v => console.log('invoice', v))
  );

  invoiceIT$ = combineLatest([this._invoiceIT$, this._property]).pipe(
    map(([invoice, property]) =>
      property ? { ...invoice, ...property } : { ...invoice }
    )
    // tap(v => console.log('invoice', v))
  );

  constructor(
    private checkoutstore: CheckoutStore,
    private configStore: ConfigStore
  ) {
    let country = '';
    this.configStore$
      .pipe(take(1))
      .subscribe((restaurant) => (country = restaurant?.country || ''));
    this.stripeConfig =
      country?.toUpperCase() === 'IT'
        ? new StripeConfigIT()
        : new StripeConfigFR();

    this.countrySelected = {
      name: this.stripeConfig.name,
      code: this.stripeConfig.code,
    };
  }

  receipt = (): Receipt => {
    const receiptStorage = localStorage.getItem('receipt');
    return receiptStorage ? JSON.parse(receiptStorage) : null;
  };

  invoice = (): InvoiceFR | InvoiceIT => {
    const invoiceStoraged = localStorage.getItem('invoice');
    return invoiceStoraged ? JSON.parse(invoiceStoraged) : null;
  };

  // invoice_04_fields = (): Array<string> => {
  //   const invoice04Storage = localStorage.getItem('payment_error_04_fields');
  //   return invoice04Storage ? JSON.parse(invoice04Storage) : null;
  // };

  dispatchInitReceipt(): void {
    // const receipt = localStorage.getItem('receipt');
    const invoiceStoraged = localStorage.getItem('invoice');
    if (invoiceStoraged) {
      const data = JSON.parse(invoiceStoraged);
      this.setCustomerType(data.type ? data.type : null);
      this.setCountry(data.country);
      this._property.next({ ...data });
    }
  }

  /**
   * set the invoice form properties formed by key:value
   * example: {country: string, tva: string .....}
   * @param data an pair key:value
   */
  dispatchProperty(data: Record<string, string>): void {
    this._property.next(data);
  }

  /**
   * set type of Customer [COMPANY, MICRO COMPANY, PRIVATE, FOREIGN]
   * @param type  {@link CustomerType}
   */
  setCustomerType(type: string): void {
    this.customerType = type;
  }

  /**
   * set Country with name and the its code.
   * example: { name: 'FR - France', code: 'FR' };
   * @param name country_name
   */
  setCountry(name: string): void {
    const code = name.split(' ')[0];
    const country: Country = { name, code };
    this.countrySelected = country;
    this.vatCodeLabel =
      name === this.stripeConfig.name
        ? environment.FranceVATCodeLabel
        : environment.AbroadVATCodeLabel;
  }

  /**
   *
   * @param receiptData Save the Invoice data (Form value) in localstorage and update the property 'send_receipt_to', 'invoice' of Order.
   * Form value contains information about the receipt:
   * email: email who want to sent the receipt to
   * invoice: invoice info
   */
  dispatchSaveReceiptData(receiptData: Receipt): void {
    // console.log('save receipt', { ...receiptData, type: this.customerType});
    const storage = localStorage.getItem('checkout');
    const analyticProducts = localStorage.getItem('analyticProducts') ?? null;
    let checkedProducts = [];
    if (analyticProducts) {
      const parsedAnalyticProducts = JSON.parse(analyticProducts);
      checkedProducts =
        parsedAnalyticProducts?.filter(
          (product: OrderItem) =>
            product.checked || !!product.shared?.currentPayment?.parts
        ) ?? [];
    }
    // const storage = localStorage.getItem('order');
    // remove comment when it's works
    if (storage) {
      const parsedStorage = JSON.parse(storage);
      const checkout: Checkout = {
        ...parsedStorage,
        send_receipt_to: receiptData.email ? receiptData.email : '',
        customer_invoice: receiptData.invoice_toggle
          ? {
              ...receiptData.invoice,
              country_code: this.countrySelected.code,
              type: this.customerType,
            }
          : null,
        products:
          checkedProducts.length > 0
            ? this.manageAnalyticProducts(checkedProducts)
            : parsedStorage.products,
      };
      this._invoice_04.next(null);
      // update localStorage
      this.checkoutstore.dispatchSetStartPaymentCheckout(checkout);
    }
    let country = '';
    this.configStore$
      .pipe(take(1))
      .subscribe((restaurant) => (country = restaurant?.country || ''));
    switch (country?.toUpperCase()) {
      case 'IT':
        this._invoiceIT = this._property.getValue();
        this.stripeConfig = new StripeConfigIT();
        this.countrySelected = {
          name: this.stripeConfig.name,
          code: this.stripeConfig.code,
        };
        break;
      case 'FR':
        this._invoiceFR = this._property.getValue();
        break;
      default:
        break;
    }
    localStorage.setItem(
      'receipt',
      JSON.stringify({ ...receiptData, type: this.customerType })
    );

    // update the invoice also with the email if it has been inserted
    const invoiceStorage = localStorage.getItem('invoice');
    if (receiptData.email && invoiceStorage) {
      const invoice = JSON.parse(invoiceStorage);
      localStorage.setItem(
        'invoice',
        JSON.stringify({
          ...invoice,
          email: receiptData.email,
          type: this.customerType,
        })
      );
    }
  }

  dispatchSetInvoice04ErrorFields(
    errors: InvoiceErrorFieldItem[] | null
  ): void {
    this._invoice_04.next(errors);
  }

  /**
   * Save the invoice data in local storage everytime input fields is changed.
   * at same time also every property, the Record<string, string> is updated
   * @param invoiceData invoice form data
   */
  dispatchSaveInvoiceData(invoiceData: InvoiceFR | InvoiceIT): void {
    let country = '';
    this.configStore$
      .pipe(take(1))
      .subscribe((restaurant) => (country = restaurant?.country || ''));

    switch (country?.toUpperCase()) {
      case 'IT':
        if (invoiceData && this.customerType) {
          this.dispatchInvoiceIT(invoiceData as InvoiceIT, this.customerType);
        }
        break;
      case 'FR':
        if (invoiceData && this.customerType) {
          this.dispatchInvoiceFR(invoiceData as InvoiceFR, this.customerType);
        }
        break;
      default:
        if (invoiceData && this.customerType) {
          this.dispatchInvoiceFR(invoiceData as InvoiceFR, this.customerType);
        }
        break;
    }
  }

  dispatchInvoiceIT(invoiceData: InvoiceIT, customerType: string) {
    const properties = this._property.getValue();
    this._property.next({
      ...properties,
      ...invoiceData,
      type: customerType,
    });
    // this._property.next({ ...{ ...invoiceData, country_code: this.countrySelected.code }, type: this.customerType });
    const invoice: InvoiceIT = {
      ...properties,
      ...invoiceData,
      country_code: this.countrySelected.code,
      type: customerType,
    };
    localStorage.setItem('invoice', JSON.stringify(invoice));
  }

  dispatchInvoiceFR(invoiceData: InvoiceFR, customerType: string) {
    const properties = this._property.getValue();
    this._property.next({
      ...properties,
      ...invoiceData,
      type: customerType,
    });
    // this._property.next({ ...{ ...invoiceData, country_code: this.countrySelected.code }, type: this.customerType });
    const invoice: InvoiceFR = {
      ...properties,
      ...invoiceData,
      country_code: this.countrySelected.code,
      type: customerType,
    };
    localStorage.setItem('invoice', JSON.stringify(invoice));
  }

  dispatchIsOpenPopover(status: PopupoverStatus): void {
    this.customerPopover.next(status);
  }

  /**
   * pristine Invoice data to null
   */
  dispatctPristineInvoice(): void {
    this._property.next(null);
  }

  manageAnalyticProducts(analyticProducts: OrderItem[]): OrderItem[] {
    const reassebledAnalyticProducts: OrderItem[] = [];
    const tempProducts: OrderItem[] = [];
    // clean ids
    analyticProducts.forEach((element) => {
      if (element.id.includes('.')) {
        const splittedId = element.id.split('.');
        tempProducts.push({
          ...element,
          id: splittedId[0],
        });
      } else {
        tempProducts.push({ ...element });
      }
    });
    // sum quantity
    tempProducts.forEach((x) => {
      const obj = reassebledAnalyticProducts.find((o) => o.id === x.id);
      if (obj) {
        obj.quantity += x.quantity;
      } else {
        reassebledAnalyticProducts.push(x);
      }
    });
    return reassebledAnalyticProducts;
  }

  clean(): void {
    this._invoice_04.next(null);
    this.dispatctPristineInvoice();
    this.dispatchIsOpenPopover('close');
    this.customerType = null;
  }
}
