/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { filter, map } from 'rxjs/operators';
import { Guest } from './../../models/guest';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GuestStore {
  public _guest = new BehaviorSubject<Guest | null>(null);
  guest$ = this._guest.asObservable();
  fastpay$ = this.guest$.pipe(
    filter(Boolean), // return guest not null
    map((guest) => guest.fast_pay_enabled)
  );

  constructor() {
    this.initGuestFromLocalStorage();
  }

  initGuestFromLocalStorage(): void {
    console.log('init guest from local storage...');
    const storage = localStorage.getItem('guest');

    if (storage) {
      const guest: Guest = JSON.parse(storage);
      this._guest.next(!!guest.order_id ? guest : null);
    }
  }

  setGuest(guest: Guest): void {
    localStorage.setItem('guest', JSON.stringify(guest));
    this._guest.next(guest);
  }

  removeGuest(): void {
    this._guest.next(null);
  }

  get userID() {
    return this._guest.getValue()?.user_id;
  }

  get orderID() {
    return this._guest.getValue()?.order_id;
  }

  get token() {
    return this._guest.getValue()?.token;
  }

  get fastPay() {
    return this._guest.getValue()?.fast_pay_enabled;
  }

  get tipSectionEnabled() {
    return this._guest.getValue()?.tip_section_enabled;
  }

  get splitPaymentEnabled() {
    return this._guest.getValue()?.split_payment_enabled;
  }

  get currency() {
    return this._guest.getValue()?.currency;
  }

  get currencySymbol() {
    return this._guest.getValue()?.currency_symbol ?? '€';
  }

  get digitalWalletEnabled() {
    const digital_wallet = this._guest.getValue()?.digital_wallet ?? false;
    const pay_with_digital_wallet =
      this._guest.getValue()?.pay_with_digital_wallet ?? false;

    return digital_wallet && pay_with_digital_wallet;
  }

  clean(): void {
    this.removeGuest();
    localStorage.removeItem('guest');
  }
}
