import { Component, EventEmitter, Input, Output } from '@angular/core';

type Direction = 'column' | 'row';

@Component({
  selector: 'app-button4pay',
  template: `
    <div
      class="l-4pay-button-item"
      [class]="{
        isDisabled: disabled ? true : false
      }"
      [style]="{
        'flex-direction': flexdirection,
        gap: flexdirection === 'row' ? '1rem' : '0.5rem',
        height: height ? height : '100%'
      }"
      tappable="true"
      (click)="!disabled ? buttonclick.emit() : ''"
    >
      <ng-content></ng-content>
      <span
        [class]="{
          'l-4pay-default-button-label': flexdirection === 'row' ? true : false,
          'l-4pay-other-button-label': flexdirection === 'column' ? true : false
        }"
        >{{ label }}</span
      >
    </div>
  `,
  // styleUrls: ['./button4pay.component.scss'],
})
export class Button4payComponent {
  @Input() label: string | null = null;
  @Input() flexdirection: Direction = 'row';
  @Input() height: string | null = null;
  @Input() disabled: boolean | null = null;
  @Output() buttonclick = new EventEmitter<void>();
}
