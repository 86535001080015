import { Component, Input } from '@angular/core';

@Component({
  selector: 'l4pay-error',
  template: `
  <div class="l4pay-error">
    <ng-content></ng-content>
    {{error}}
  </div>
  `
})
export class L4PayErrorComponent {
  @Input() error: string | null = null;
}
