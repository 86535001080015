import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { L4PayToggleGroupComponent } from './l4pay-toggle-group.component';
import { L4PayToggleComponent } from './l4pay-toggle.component';
import { ToggleTypeDirective } from './toggle-type.directive';

const components = [
  L4PayToggleGroupComponent,
  L4PayToggleComponent,
  ToggleTypeDirective
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components]
})
export class L4PayButtonToggleModule {}
