import { GuestStore } from './../stores/guest.store';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, exhaustMap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';

/**
 * Adds "withCredentials" to send cookies to the backend
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private guestStore: GuestStore) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf(env.fidelityUrl) !== -1) {
      return next.handle(req);
    }

    return this.guestStore.guest$.pipe(
      take(1),
      exhaustMap((guest) => {
        const jsonContentHeader = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (!guest) {
          return next.handle(jsonContentHeader);
        }
        // clone request and replace 'http://' with 'https://' at the same time
        // const secureReq = req.clone({
        //   url: req.url.replace('http://', env.production ? 'https://' : 'http://')
        // });
        // console.log('secureReq', secureReq);

        const authReq = jsonContentHeader.clone({
          setHeaders: {
            Authorization: `Bearer ${guest.token}`,
          },
        });
        // console.log('con authorization');
        return next.handle(authReq);
      })
    );
  }
}
