import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

export const NUMBER_FORMAT_REGEXP = /^(\d+)?\.((\d+)(-(\d+))?)?$/;
export const NUMBER_REGEXP = /^[1-9][\.\d]*(,\d+)?$/i;

@Directive({
  selector: '[l4pay-currency]'
})
export class CurrencyTypeDirective {
  @Input() decimalSeparator = ['.', ','];
  @Input() pattern?: string | RegExp;
  inputElement!: HTMLInputElement;
  private regex: RegExp | null = null;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear'
  ];

  private hasDecimalPoint = false;
  constructor(private e: ElementRef) {
    this.inputElement = e.nativeElement;
  }

  @HostListener('keydown', ['$event'])
    handleKeyboardEvent(e: KeyboardEvent) {
      if (
        this.navigationKeys.indexOf(e.key) > -1  // Allow: navigation keys: backspace, delete, arrows etc.
      ) {
        // let it happen, don't do anything
        return;
      }

      let newValue = '';
      if (e.key && this.decimalSeparator.includes(e.key)) {
        newValue = this.forecastValue(e.key).replace(',','.');
        // console.log('newValueWithComma', newValue.split(','));
        // console.log('newValueWithDot', newValue.split('.'));
        if (newValue.split(',').length === 1 && newValue.split('.').length > 2) {
          // has two or more decimal points
          e.preventDefault();
          return;
        } else if (newValue.split(',').length === 2 && newValue.split('.').length === 2) {
          // has two or more decimal points
          e.preventDefault();
          return;
        } else {
          this.hasDecimalPoint = newValue.indexOf(',') > -1;
          return; // Allow: only one decimal point
        }
      }

      if (e.key) { // not allow more than 2 numbers after decimal point
        newValue = this.forecastValue(e.key).replace(',','.');
        if (newValue.split('.')[1] && newValue.split('.')[1].length > 1) {
          e.preventDefault();
          return;
        }
      }

      // Ensure that it is a number and stop the keypress
      if (e.key === ' ' || isNaN(Number(e.key))) {
        e.preventDefault();
        return;
      }
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    return (
      oldValue.substring(0, selectionStart) +
      key +
      oldValue.substring(selectionEnd)
    );
  }
}
