import { Injectable, Pipe, PipeTransform } from '@angular/core';

const entities: Record<string, string> = {
  'amp': '&',
  'apos': '\'',
  '#x27': '\'',
  '#x2F': '/',
  '#39': '\'',
  '#47': '/',
  '#38': '&',
  'lt': '<',
  'gt': '>',
  'nbsp': ' ',
};

@Pipe({
  name: 'decode'
})
export class DecodePipe implements PipeTransform {
  transform(value: string = '') {
    return value.replace(/&([^;]+);/gm,  (match ,text)=> entities[text] || match );
  }
}
