import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

export type Toggle = 'tip' | 'survey';

@Component({
  selector: 'app-l4pay-toggle',
  template: `
    <div
      #card
      [ngClass]="{ 'card-selected': opened }"
      toggleType
      [type]="type"
      [checked]="opened"
      [survey]="survey"
      [data]="data"
      tappable
      (click)="onClick()"
      [ngStyle]="{
        'text-align': 'center',
        width: width ? width : '',
        height: height ? height : '',
        'border-color': borderColor ? borderColor : ''
      }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .card {
        background: var(--4pay-card-bkg);
        min-width: 80px;
        height: 55px;
        display: table-caption;
        border: 2px solid var(--ion-color-light-shade);
        border-radius: 5px;
        color: var(--ion-color-light-shade);
        font-size: large;
        font-weight: 600;
        padding: 0 0.2rem;
      }

      .card-selected {
        border-color: var(--4pay-card-tip-color) !important;
        color: var(--4pay-card-tip-color);
      }
    `,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class L4PayToggleComponent {
  @Input() opened = false;
  @Input() survey = false;
  @Input() type: Toggle = 'tip';
  @Input() width: string | null = null;
  @Input() height: string | null = null;
  @Input() borderColor: string | null = null;
  @Input() data: any | null = null;
  @Output() toggle = new EventEmitter<any>();
  @ViewChild('card') card!: ElementRef<HTMLDivElement>;

  onClick(): void {
    // console.log('card selected', this.data);
    this.toggle.emit(this.data);
  }
}
