import { translations } from 'src/assets/data/translations';
import { GuestStore } from './../stores/guest.store';
import { serviceNotAvailable } from './../../models/error-response';
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-var */
/* eslint-disable jsdoc/newline-after-description */
import { ConnectionType, GlobalStore } from './../stores/global.store';
import { ReceiptStore } from 'src/app/core/stores/receipt.store';
import { OrderItem } from './../../models/order-item';
import {
  Error,
  ErrorResponse,
  ERROR_CODE,
} from './../../models/error-response';
import { PaymentStore } from './../stores/payment.store';
import { Checkout } from './../../models/checkout';
import { CheckoutStore } from './../stores/checkout.store';
import { Guest } from 'src/app/models/guest';
import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { environment as env } from './../../../environments/environment';
import { OrderStore } from '../stores/order.store';
import { Router, ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/order';

import { HttpHandlerErrorService } from './http-handler-error.service';
import { AuthStore } from '../stores/auth.store';
import { AlertController, ModalController } from '@ionic/angular';
import { InvoiceErrorFieldItem } from 'src/app/models/invoice-error-field-item';
import { L4PayErrorComponent } from 'src/app/shared/l4pay-form/l4pay-error.component';
import Pusher from 'pusher-js';
import { filter, take } from 'rxjs';
import { LanguageService } from './language.service';
import { GeneralErrorComponent } from 'src/app/shared/components/general-error/general-error.component';
declare let require: any;
@Injectable({ providedIn: 'root' })
export class WebSocketService {
  //socket
  echo: any;
  constructor(
    private guestStore: GuestStore,
    private orderStore: OrderStore,
    private checkoutStore: CheckoutStore,
    private receiptStore: ReceiptStore,
    private paymentStore: PaymentStore,
    private authStore: AuthStore,
    private router: Router,
    private errorHandlerService: HttpHandlerErrorService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    private globalStorage: GlobalStore,
    private lang: LanguageService
  ) {}

  init(guest: Guest): void {
    // this.authStore.dispatchLoader(false);
    console.log('init websocket...');
    try {
      const pusher = require('pusher-js');
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: env.pusherAppKey,
        cluster: env.pusherAppCluster,
        // wsHost: window.location.hostname,
        wsHost: env.wshost,
        wsPort: env.wsPort,
        // wssPort: 6001,
        forceTLS: false,
        disableStats: true,
        encrypted: env.wsEncrypted,
        authEndpoint: env.host + 'broadcasting/auth',
        auth: {
          headers: {
            Authorization: `Bearer ${guest.token}`,
            'X-App-ID': env.appId,
          },
        },
      });

      this.echo.connector.pusher.connection.bind(
        'state_change',
        (state: any) => {
          // console.log('ws state_change', state);
          if (
            state.previous === 'connected' &&
            state.current === 'connecting'
          ) {
          }
          if (
            state.previous === 'connecting' &&
            state.current === 'unavailable'
          ) {
            console.log('state von connecting to unavailable');
            this.globalStorage.dispatchErrorConnection({
              type: 'ws',
              error: true,
            });
            const msg: Record<ConnectionType, string> = {
              ws: 'Service Connecting...',
              network: 'Service not available: Network offline',
            };
            this.globalStorage.dispatchConnectionStateMessage(msg);
          }
          if (
            state.previous === 'connecting' &&
            state.current === 'connected'
          ) {
            this.globalStorage.dispatchErrorConnection({
              type: 'ws',
              error: false,
            });
          }
          if (
            state.previous === 'unavailable' &&
            state.current === 'connected'
          ) {
            this.globalStorage.dispatchErrorConnection({
              type: 'ws',
              error: false,
            });
          }
          if (state.current === 'unavailable') {
            console.log('ws unavailable');
          }
        }
      );
    } catch (e: any) {
      this.errorHandlerService.errorToast('websocket connection error' + e);
    }
  }

  /**
   * Open OrderContent Channel to receive the order without tips.
   * @param id require order_id
   */
  openOrderContentChannel(id: number): void {
    console.log('open order content channel', id);
    this.echo
      .private(`OrderContent.${id}`)
      .listen('OrderContentEvent', (message: any) => {
        console.log('orderContentEvent - order received', message);
        this.authStore.dispatchLoader(false);
        if (!message.response.errors) {
          // order received
          const order: Order = message.response;
          this.orderStore.dispatchSetOrder(order);
        } else {
          const errResponse: ErrorResponse = message.response; // errorStatus obj
          this.orderStore.dispatchEmptyOrder();
          // this.errorHandlerService.errorToast();
          if (
            (errResponse.errors.length > 0 &&
              errResponse.errors[0].code === ERROR_CODE.ORDER) ||
            errResponse.invalidate_status
          ) {
            this.errorAlert(
              errResponse.errors,
              errResponse.invalidate_status,
              '02'
            );
          }
        }
        this.leaveOrderContentChannel(id); // close the channel
      });

    this.echo.connector.pusher
      .channel(`private-OrderContent.${id}`)
      .bind('pusher:subscription_succeeded', () => {
        console.log(`private-OrderContent.${id}: subscribed`);
        this.orderStore.dispatchSubscribeChannel(true);
      });
    this.echo.connector.pusher
      .channel(`private-OrderContent.${id}`)
      .bind('pusher:subscription_error', (error: any) => {
        var { status } = error;
        console.log(`private-OrderContent.${id}: error`);
        console.log('status', status);
        this.orderStore.dispatchEmptyOrder();
        this.orderStore.dispatchSubscribeChannel(false);
        this.errorAlert(serviceNotAvailable(), false, '02');
      });
  }

  /**
   * Open StartCheckout Channel to receive the order with tips.
   * @param id require order_id.
   */
  openStartCheckoutChannel(id: number): void {
    console.log('open start checkout channel', id);
    this.echo
      .private(`StartCheckout.${id}`)
      .listen('StartCheckoutEvent', (message: any) => {
        console.log('StartCheckoutEvent - order with tips received', message);
        if (!message.response.errors) {
          // checkout received
          const checkout: Checkout = message.response;
          // order with tips received - to active after the cashsystem works
          this.checkoutStore.dispatchSetCheckout(checkout);
          if (
            checkout?.payment_proposal_confirm &&
            checkout?.payment_proposal_confirm?.payment_mode === 3
          ) {
            const splitAmount =
              checkout?.payment_proposal_confirm?.amount_to_pay ?? 0;
            this.checkoutStore.dispatchSetSplitAmount(splitAmount);
          }
        } else {
          const errResponse: ErrorResponse = message.response; // errorStatus obj
          this.checkoutStore.dispatchEmptyCheckout();
          if (errResponse.errors.length > 0) {
            // && (errResponse.errors[0].code === ERROR_CODE.CHECKOUT || errResponse.errors[0].code === ERROR_CODE.NOT_POSSIBLE_CHECKOUT)) || errResponse.invalidate_status
            this.errorAlert(
              errResponse.errors,
              errResponse.invalidate_status,
              '03'
            );
          }
        }
        this.leaveStartCheckoutChannel(id); // close the channel
      });

    this.echo.connector.pusher
      .channel(`private-StartCheckout.${id}`)
      .bind('pusher:subscription_succeeded', () => {
        console.log(`private-StartCheckout.${id}: subscribed`);
        this.checkoutStore.dispatchSubscribeChannel(true);
      });
    this.echo.connector.pusher
      .channel(`private-StartCheckout.${id}`)
      .bind('pusher:subscription_error', (error: any) => {
        var { status } = error;
        console.log(`private-StartCheckout.${id}: error`);
        console.log('status', status);
        this.checkoutStore.dispatchEmptyCheckout();
        this.checkoutStore.dispatchSubscribeChannel(false);
        this.errorAlert(serviceNotAvailable(), false, '03');
      });
  }

  /**
   * Open FastPayment Channel when the fastpay flag is enabled.
   * @param id require order_id.
   */
  openFastPaymentChannel(id: number): void {
    console.log('open start fast payment channel', id);
    this.echo
      .private(`FastPayment.${id}`)
      .listen('FastPaymentEvent', (message: any) => {
        console.log('FastPaymentEvent - order with tips received', message);
        if (!message.response.errors) {
          // checkout received
          const checkout: Checkout = message.response;
          // order with tips received
          this.guestStore.guest$
            .pipe(filter(Boolean), take(1))
            .subscribe((guest) => {
              if (guest.fast_pay_enabled && guest.tip_section_enabled) {
                // with tip_section_enabled = true, the store startPaymentCheckout will loaded in the second step, requires for the payment gateway
                this.checkoutStore.dispatchSetCheckout(checkout);
              } else {
                // with tip_section_enabled = false, the store startPaymentCheckout needs immediately loaded, requires for the payment gateway
                this.checkoutStore.dispatchSetStartPaymentCheckout(checkout);
              }
            });
          // fast payment with flag
        } else {
          const errResponse: ErrorResponse = message.response; // errorStatus obj
          // TEST FastPay - 'not possible now'
          // const errResponse: ErrorResponse = {invalidate_status: false, errors: [ { code: 8, desc: 'Not possible now' } ]}; // errorStatus obj
          this.checkoutStore.dispatchEmptyCheckout();
          if (errResponse.errors.length > 0) {
            this.errorAlert(
              errResponse.errors,
              errResponse.invalidate_status,
              '20'
            );
          }
        }
        this.leaveStartFastPaymentChannel(id); // close the channel
      });

    this.echo.connector.pusher
      .channel(`private-FastPayment.${id}`)
      .bind('pusher:subscription_succeeded', () => {
        console.log(`private-StartFastPayment.${id}: subscribed`);
        this.checkoutStore.dispatchSubscribeChannel(true);
      });
    this.echo.connector.pusher
      .channel(`private-FastPayment.${id}`)
      .bind('pusher:subscription_error', (error: any) => {
        var { status } = error;
        console.log(`private-StartFastPayment.${id}: error`);
        console.log('status', status);
        this.checkoutStore.dispatchEmptyCheckout();
        this.checkoutStore.dispatchSubscribeChannel(false);
        this.errorAlert(serviceNotAvailable(), false, '20');
      });
  }

  /**
   * Open StartPaymentProcess Channel for payment payXpert
   * @param id require order_id
   */
  openStartPaymentProcessChannel(id: number | undefined): void {
    if (id) {
      console.log('open start payment process channel', id);
      this.echo
        .private(`StartPaymentProcess.${id}`)
        .listen('StartPaymentProcessEvent', (message: any) => {
          console.log('StartPaymentProcessEvent', message);
          if (!message.response.errors) {
            this.paymentStore.dispatchSetPaymentResponse(message.response);
          } else {
            const errResponse: ErrorResponse = message.response; // errorStatus obj
            // error occured due step 04 - invoice fields
            const invoiceFields =
              errResponse.errors.length > 0
                ? this.getFields(errResponse.errors)
                : null;
            // console.log('fields', invoiceFields);
            // error occured due step 05
            if (errResponse.invalidate_status) {
              this.errorAlert(
                errResponse.errors.length > 0 ? errResponse.errors : null,
                errResponse.invalidate_status,
                '04'
              ); // abort from cashsystem
            } else if (errResponse.errors.length === 1 && !invoiceFields) {
              this.errorAlert(errResponse.errors, false, '04');
            } else if (invoiceFields) {
              // this.receiptStore.dispatchSetInvoice04ErrorFields(invoiceFields);
              this.errorAlert(errResponse.errors, false, '04');
            }
          }
          this.leaveStartPaymentProcess(id); //close the channel
        });

      this.echo.connector.pusher
        .channel(`private-StartPaymentProcess.${id}`)
        .bind('pusher:subscription_succeeded', () => {
          console.log(`private-StartPaymentProcess.${id}: subscribed`);
          this.paymentStore.dispatchSubscribeChannel(true);
        });
      this.echo.connector.pusher
        .channel(`private-StartPaymentProcess.${id}`)
        .bind('pusher:subscription_error', (error: any) => {
          var { status } = error;
          console.log(`private-StartPaymentProcess.${id}: error`);
          console.log('status', status);
          this.paymentStore.dispatchSubscribeChannel(false);
          this.errorAlert(serviceNotAvailable(), false, '04');
        });
    }
  }

  /**
   *
   * @param errors
   * @returns
   */
  getFields(errors: Array<Error>): InvoiceErrorFieldItem[] | null {
    const errorsItem: InvoiceErrorFieldItem[] = [];
    errors.map((error) =>
      error.field
        ? errorsItem.push({
            component: L4PayErrorComponent,
            error: { ...error, field: this.formatterField(error.field) },
          })
        : error
    );
    return errorsItem.length > 0 ? errorsItem : null;
  }

  /**
   *
   * @param path
   * @returns
   */
  formatterField(path: string): string {
    return path.split('.')[path.split('.').length - 1];
  }

  /**
   * Open PayWithStripeProcess Channel using Stripe
   * @param id require order_id
   */
  /**
   * Payment Stripe
   * @payment_method_id : 'card'
   * @payment_intent_id : intent_id received from the server after
   * @returns
   * {
   *   status: 'succeeded | requires_action | requires_payment_method';
   *   url: string;
   *   return_url: string;
   * }
   */
  openPayProcessChannel(id: number | undefined): void {
    if (id) {
      console.log('open payment channel', id);
      this.echo
        .private(`PayWithGatewayProcess.${id}`)
        .listen('PayWithGatewayProcessEvent', (message: any) => {
          console.log('PayWithGatewayProcessEvent', message);
          // todo something
          if (message.response.success) {
            this.paymentStore.dispatchCompletePayment(); // emit true
          } else {
            this.errorHandlerService.errorToast(
              'An error occurred: Service not available; please try again later.'
            );
            this.paymentStore.dispatchStartPayment(false); // active the pay button
          }
          //close the channel
          this.leavePayWithStripeProcess(id);
        });
    }
  }

  /**
   * Open PaymentCompleted Channel
   * @param id require order_id
   */
  openPaymentCompletedChannel(
    id: number | undefined,
    fromPage: 'survey' | 'stripe' | 'payxpert'
  ): void {
    if (id) {
      console.log('open payment completed channel', id);
      this.echo
        .private(`PaymentCompleted.${id}`)
        .listen('PaymentCompletedEvent', (message: any) => {
          console.log('PaymentCompletedEvent', message);
          // todo something
          if (!message.response.errors) {
            this.paymentStore.dispatchSetPaymentCompleteResponse(
              message.response
            );
          } else {
            const errResponse: ErrorResponse = message.response;
            if (errResponse.invalidate_status) {
              this.errorAlert(null, errResponse.invalidate_status, '06'); // abort from cashsystem
            } else {
              // open ErrorPage
              this.paymentStore.dispatchPaymentFailMessage(
                errResponse.errors[0].desc
              );
              this.paymentStore.dispatchSetPaymentCompleteResponse({
                success: false,
              });
              // console.log('naviga a error page');
              this.router.navigateByUrl('/error', { replaceUrl: true });
            }
            this.paymentStore.dispatchSetPaymentCompleteResponse(null);
          }
          // close the channel
          this.leavePaymentCompletedProcess(id);
        });

      this.echo.connector.pusher
        .channel(`private-PaymentCompleted.${id}`)
        .bind('pusher:subscription_succeeded', () => {
          console.log(`private-PaymentCompleted.${id}: subscribed`);
          fromPage === 'stripe' || fromPage === 'payxpert'
            ? this.paymentStore.dispatchPaymentSubscribeChannel(true)
            : this.paymentStore.dispatchSubscribeChannel(true);
        });
      this.echo.connector.pusher
        .channel(`private-PaymentCompleted.${id}`)
        .bind('pusher:subscription_error', (error: any) => {
          var { status } = error;
          console.log(`private-PaymentCompleted.${id}: error`);
          console.log('status', status);
          fromPage === 'stripe' || fromPage === 'payxpert'
            ? this.paymentStore.dispatchPaymentSubscribeChannel(false)
            : this.paymentStore.dispatchSubscribeChannel(false);
        });
    }
  }

  /**
   * Open PaymentCompleted Channel
   * @param id require order_id
   */
  openAbortAllSessionChannel(uuid: string): void {
    console.log('open abort all session channel', uuid);
    this.echo
      .private(`AbortAllSessions.${uuid}`)
      .listen('AbortAllSessionsEvent', (message: any) => {
        console.log('AbortAllSessionsEvent', message);
        // todo something
        if (message.response.abort) {
          // abort all sessions
          // this.globalStorage.dispatchAbortAllSessions(true);
          this.errorAlert(null, true, '06');
        }
        // close the channel
        this.leaveAbortAllSessionsProcess(uuid);
      });
  }

  /**
   * Leave OrderContent Channel
   * @param id require oder_id
   */
  leaveOrderContentChannel(id: number | undefined): void {
    if (id) {
      console.log(`leave OrderContent.${id}`);
      this.echo.leave(`OrderContent.${id}`);
      this.orderStore.dispatchSubscribeChannel(false);
    }
  }

  /**
   * Leave StartCheckout Channel
   * @param id require oder_id
   */
  leaveStartCheckoutChannel(id: number | undefined): void {
    if (id) {
      console.log(`leave StartCheckout.${id}`);
      this.echo.leave(`StartCheckout.${id}`);
    }
  }

  /**
   * Leave StartFastPayment Channel
   * @param id require oder_id
   */
  leaveStartFastPaymentChannel(id: number | undefined): void {
    if (id) {
      console.log(`leave FastPayment.${id}`);
      this.echo.leave(`FastPayment.${id}`);
    }
  }

  /**
   * Leave StartPaymentProcess Channel
   * @param id require oder_id
   */
  leaveStartPaymentProcess(id: number | undefined): void {
    if (id) {
      console.log(`leave StartPaymentProcess.${id}`);
      this.echo.leave(`StartPaymentProcess.${id}`);
    }
  }

  /**
   * Leave PayWithStripeProcess Channel
   * @param id require oder_id
   */
  leavePayWithStripeProcess(id: number | undefined): void {
    if (id) {
      console.log(`leave PayWithGatewayProcess.${id}`);
      this.echo.leave(`PayWithGatewayProcess.${id}`);
    }
  }

  /**
   * Leave PaymentCompleted Channel
   * @param id require oder_id
   */
  leavePaymentCompletedProcess(id: number | undefined): void {
    if (id) {
      console.log(`leave PaymentCompleted.${id}`);
      this.echo.leave(`PaymentCompleted.${id}`);
    }
  }

  leaveAbortAllSessionsProcess(id: string | undefined): void {
    if (id) {
      console.log(`leave AbortAllSessionsProcess.${id}`);
      this.echo.leave(`AbortAllSessionsProcess.${id}`);
    }
  }

  /**
   *
   * @param error invalid_status = false, we receive a list of errors
   * 1 = 'table not found'
   * 123 = 'errors field'
   * 12 = 'other type of error'
   * @param invalid_status status = true, an abort event has occured
   *
   */
  async errorAlert(
    errors: Error | Error[] | null,
    invalid_status: boolean,
    phase: string = ''
  ) {
    const genericErrorModal = await this.modalCtrl.create({
      component: GeneralErrorComponent,
      componentProps: {
        errors,
        invalid_status,
        phase,
      },
      mode: 'md',
      backdropDismiss: false,
    });

    genericErrorModal.present();

    // let outputMessage = '';
    // if (error && error instanceof Array) {
    //   error.forEach((element) => {
    //     outputMessage += `<div> ${element.desc} </div>`;
    //   });
    // }
    // const alert = await this.alertController.create({
    //   // cssClass: 'my-custom-class',
    //   header: translations[this.lang.language].information,
    //   mode: 'ios',
    //   backdropDismiss: false,
    //   message: outputMessage ? outputMessage : 'An error has occured',
    //   buttons: [
    //     {
    //       text: 'Ok',
    //       id: 'confirm-button',
    //       handler: () => {
    //         console.log(this.modalCtrl);
    //         if (this.modalCtrl) {
    //           this.modalCtrl
    //             .dismiss()
    //             .catch((e) => console.log('no modal to dismiss'));
    //         }
    //         if (invalid_status) {
    //           // invalid_status = true, an abort event has occurred
    //           console.log('back to home');
    //           this.modalCtrl.dismiss();
    //           this.globalStorage.cleanAll();
    //           // this.router.navigateByUrl('/');
    //         } else {
    //           switch (error instanceof Array && error[0].code) {
    //             case ERROR_CODE.WS_NOT_AVAILABLE:
    //               this.router.navigateByUrl('/', { replaceUrl: true });
    //               break;
    //             case ERROR_CODE.ORDER: // error = 1 -> back to home
    //               this.router.navigateByUrl('/', { replaceUrl: true });
    //               break;
    //             case ERROR_CODE.CHECKOUT:
    //               this.router.navigateByUrl('/order', { replaceUrl: true });
    //               break;
    //             case ERROR_CODE.NOT_POSSIBLE_CHECKOUT:
    //               break;
    //             default:
    //               this.router.navigateByUrl('/order', { replaceUrl: true });
    //               break;
    //           }
    //         }
    //       },
    //     },
    //   ],
    // });

    // await alert.present();
    // await alert.onDidDismiss();
  }
}
