import { CustomerType } from '../models/invoice';

/**
 * @key  fieldName of the form.
 * @value  an array of custormer that use a specific field (key)
 * not all fields are mandatory for every customer.
 * here a record map to describes which are the controlName (field) mandatory for a specific customer
 */
export const fields: Record<string, Array<string>> = {
  country: [
    CustomerType.CUSTOMER1,
    CustomerType.CUSTOMER2,
    CustomerType.CUSTOMER3,
    CustomerType.CUSTOMER4,
    CustomerType.CUSTOMER5,
    CustomerType.CUSTOMER6,
    CustomerType.CUSTOMER7,
  ],
  tva: [CustomerType.CUSTOMER1, CustomerType.CUSTOMER4],
  siret: [CustomerType.CUSTOMER1, CustomerType.CUSTOMER2],
};

/**
 * a map configuration to manage which are the fieldname must be disabled according the customerType selected
 */
export const customerTypeFiedlsFR: { [key in CustomerType]: Array<string> } = {
  [CustomerType.CUSTOMER1]: [],
  [CustomerType.CUSTOMER2]: ['tva'],
  [CustomerType.CUSTOMER3]: ['tva', 'siret'],
  [CustomerType.CUSTOMER4]: ['siret'],
  [CustomerType.CUSTOMER5]: [],
  [CustomerType.CUSTOMER6]: [],
  [CustomerType.CUSTOMER7]: [],
};

export const customerTypeFiedlsIT: { [key in CustomerType]: Array<string> } = {
  [CustomerType.CUSTOMER1]: [],
  [CustomerType.CUSTOMER2]: [],
  [CustomerType.CUSTOMER3]: [],
  [CustomerType.CUSTOMER4]: [],
  [CustomerType.CUSTOMER5]: [],
  [CustomerType.CUSTOMER6]: [],
  [CustomerType.CUSTOMER7]: [],
};
