import { LanguageService } from 'src/app/core/services/language.service';
import { ConnectionType, GlobalStore } from './core/stores/global.store';
import { ConfigStore } from 'src/app/core/stores/config.store';
import { WebSocketService } from './core/services/websocket.service';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OrderStore } from 'src/app/core/stores/order.store';
import { fromEvent, Observable, Subject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  offlineEvent$: Observable<Event> | null = null;
  onlineEvent$: Observable<Event> | null = null;

  subs = new SubSink();
  constructor(
    private platform: Platform,
    private ws: WebSocketService,
    private orderStore: OrderStore,
    private configStore: ConfigStore,
    private globalStore: GlobalStore,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.platform.ready().then(() => {
      console.log('platform ready', this.platform);
      const guest = localStorage.getItem('guest');
      const auth = localStorage.getItem('auth');
      const config = localStorage.getItem('config_styles');
      // default css styles
      if (config) {
        this.configStore.dispatchSetConfigStyleFromLocalStorage(
          JSON.parse(config)
        );
      }
      if (guest && auth) {
        // gonna use the guest value saved in localstorage
        // reset to null the current order obj in local storage
        console.log('START WITH A GUEST ALREADY CREATED');
        this.orderStore.resetOrder();
        this.ws.init(JSON.parse(guest));
        this.globalStore.dispatchWsInitialized();
      }
      // pre load the connection error modal
      // this.errorHandlerService
      //   .wsConnectionErrorModal('The connection is temporarily unavailable. The connection will be restored shortly...');
      //
      this.handlerNetworkChanges();
      // initialize translations
      this.initLang(navigator.language);
      this.languageService.initTranslations();
    });
  }

  handlerNetworkChanges(): void {
    this.onlineEvent$ = fromEvent(window, 'online');
    this.offlineEvent$ = fromEvent(window, 'offline');

    this.subs.add(
      this.onlineEvent$.subscribe((event) => {
        console.log('online');
        this.globalStore.dispatchNetworkConnectionChanges(true);
      })
    );

    this.subs.add(
      this.offlineEvent$.subscribe((event) => {
        console.log('offline');
        this.globalStore.dispatchNetworkConnectionChanges(false);
        const msg: Record<ConnectionType, string> = {
          ws: '',
          network: 'Service not available: Network offline',
        };
        this.globalStore.dispatchConnectionStateMessage(msg);
      })
    );
  }

  initLang(language: string) {
    switch (language) {
      case 'it-IT':
        this.languageService.setLanguage('it');
        break;
      case 'fr-FR':
        this.languageService.setLanguage('fr');
        break;
      case 'de-DE':
        this.languageService.setLanguage('de');
        break;
      default:
        break;
    }
  }
}
