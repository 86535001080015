import { Error } from 'src/app/models/error-response';
import { L4PayErrorComponent } from './../l4pay-form/l4pay-error.component';
import { filter, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ReceiptStore } from 'src/app/core/stores/receipt.store';
import { from, Subscription } from 'rxjs';
import { Directive, OnInit, OnDestroy, TemplateRef, ViewContainerRef, Input, Renderer2, Type } from '@angular/core';


/**
 * Directive dynamically creates the component 'L4PayErrorComponent' showing the field that went wrong during startPaymentProcess (step 04).
 * @Input field => invoice form fields
 */
@Directive({
  selector: '[if4payInvoiceErrorLabel]'
})
export class IfPaymentInvoiceErrorDirective implements OnInit, OnDestroy{
  sub!: Subscription;
  field: string | null = null;
  constructor(
    private receiptStore: ReceiptStore,
    private view: ViewContainerRef,
    private renderer: Renderer2
  ){}

  @Input() set if4payInvoiceErrorLabel(field: string) {
    this.field = field;
  }

  ngOnInit(): void {
    this.sub = this.receiptStore.invoice_04_fields$
      .pipe(
        filter(Boolean),
        distinctUntilChanged()
      )
      .subscribe(errors => {
        const found = errors.find(invoiceErrorItem => invoiceErrorItem.error.field === this.field);
        if (found) { // invoice field matched
          // L4PayErrorComponent will be instantiated <l4pay-error>{{ found.error.desc }}</l4pay-error>
          const errorLabel = this.view.createComponent<L4PayErrorComponent>(found.component);
          errorLabel.instance.error = found.error.desc;
        } else {
          this.view.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
