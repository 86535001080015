import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { translations } from 'src/assets/data/translations';
@Pipe({
  name: 'translate'
})
export class LanguagePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(key: string | null) {
    if (key) {
      // translations from service
      // if (this.languageService.translations) {
      //   // translations from service
      //   // console.log('from service', this.languageService.translations[this.languageService.language][key]);
      //   return this.languageService.translations[this.languageService.language][key] !== undefined
      //   ? this.languageService.translations[this.languageService.language][key]
      //   : this.languageService.translations[this.languageService.default][key]; // default = 'en'
      // }
      // translations from local
      // console.log('from local');
      return translations[this.languageService.language][key] !== undefined
      ? translations[this.languageService.language][key]
      : translations[this.languageService.default][key]; // default = 'en'
    }
    return 'N/A';
  }
}
