import { GlobalStore } from './../../core/stores/global.store';
import { tap, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'l4pay-connection-error-modal',
  template: `
    <div class="container">
      <div class="error_card ion-padding">
        <h5>{{ connectionStateMessage$ | async }}</h5>
      </div>
    </div>
    <h1></h1>
  `,
  styles: [
    `
      $color: 'primary';
      .container {
        z-index: 9;
        padding: 16px;
        height: 100vh;
        background: rgb(27 24 24 / 62%);
        color: #fff;
      }
      .error_card {
        background: var(--ion-color-#{$color});
        border-radius: 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionErrorModalComponent implements OnDestroy {
  connectionStateMessage$ = combineLatest([
    this.globalStore.networkConnection$,
    this.globalStore.errorConnection$, // ws connection
    this.globalStore.connectionStateMessage$,
  ]).pipe(
    tap((v) => console.log('connection state message', v)),
    map(([network, ws, message]) => {
      if (network && message && ws) {
        // no ws connection --> view the message
        return message.ws;
      }
      return message ? message.network : '';
    })
  );
  constructor(public globalStore: GlobalStore) {}

  ngOnDestroy(): void {
    console.log('ConnectionErrorModal - ngOnDestroy');
  }
}
