<ion-content  #content [scrollEvents]="true" fullscreen="true">
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md" color="danger">
      <ion-title class="ion-text-center" color="tertiary">{{ 'payment_error_header_label' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <div class="l4pay-payment-feedback-content">
    <div class="payment-result">
      <div class="result">{{ 'payment_error_desc' | translate }} <ion-icon color="danger" name="close-outline"></ion-icon></div>
      <div class="error-message" *ngIf="(paymentFailMessage$ | async) as failMessage">
        <ion-icon name="warning-outline" color="danger" class="error-icon"></ion-icon>
        <ion-label color="primary ion-text-center">
          {{ failMessage }}
        </ion-label>
      </div>
      <div class="receipt" tappable (click)="goTo()">
          <ion-button expand="block" fill="outline" size="large" color="primary">
            {{ 'payment_error_retry_button' | translate }}
            <ion-icon color="primary" name="reload-outline"></ion-icon>
          </ion-button>
      </div>
    </div>
  </div>
</ion-content>
