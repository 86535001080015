import { translations } from 'src/assets/data/translations';
import { map, shareReplay, take } from 'rxjs/operators';
import { ApiServeice } from './../../core/services/api.service';
import { PaymentStore } from './../../core/stores/payment.store';
import { environment as env } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.page.html',
})
export class ErrorPage implements OnInit {
  // checkoutPage = `${environment.l4pay}/order/checkout`;
  paymentFailMessage$ = this.paymentStore.paymentFailMessage$.pipe(
    map((message) =>
      message ? message : translations[env.language][env.serviceNotAvailable]
    ),
    shareReplay()
  );
  constructor(
    private router: Router,
    private paymentStore: PaymentStore,
    private api: ApiServeice
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('payment');
    localStorage.removeItem('receipt');
  }

  goTo(): void {
    this.api.requestAbortProcess().pipe(take(1)).subscribe();
    this.paymentStore.backToPaymentPage();
    window.location.href = `${env.l4pay}/order/checkout?abort=true`;
    // this.router.navigateByUrl('/order/checkout?abort=true');
  }
}
