/* eslint-disable @typescript-eslint/quotes */
// default translations: available for en, fr
export const translations: { [key: string]: any } = {
  en: {
    home_view_order_label: 'View Order & Pay',
    home_order_now_label: 'Order Now',
    home_menu_label: 'View Menu',
    home_offers_label: 'Offered',
    footer_pay_button: 'PAY',
    order_header_label: 'VIEW ORDER AND PAY',
    order_detail_subtotal_label: 'SUBTOTAL',
    order_detail_total_label: 'TOTAL',
    order_detail_table_label: 'TABLE',
    order_footer_button: 'CHECKOUT',
    checkout_header_label: 'CHECKOUT',
    checkout_detail_discount_label: 'DISCOUNT',
    checkout_detail_tip_label: 'TIP',
    checkout_detail_total_amount_due_label: 'TOTAL AMOUNT DUE',
    checkout_addtip_label: 'ADD A TIP?',
    checkout_footer_button: 'PROCEED',
    receipt_header_label: 'RECEIPT',
    receipt_send_to_label: 'SEND RECEIPT',
    receipt_hide_items_label: 'AGREED MEAL',
    receipt_receive_invoice_label: 'RECEIVE INVOICE',
    receipt_customer_option_label: 'SELECT AN OPTION',
    receipt_email_valid: 'Not a valid email',
    receipt_email_required: 'Field is required',
    form_field_required: 'Field is required',
    invoice_form_country: 'COUNTRY',
    invoice_form_france_vat_code: 'TVA',
    invoice_form_abroad_vat_code: 'VAT-Code',
    invoice_form_siret_label: 'SIRET',
    invoice_form_company_label: 'COMPANY',
    invoice_form_city_label: 'CITY',
    invoice_form_zip_code_label: 'ZIP-CODE',
    invoice_form_address_label: 'ADDRESS',
    invoice_form_province_label: 'PROVINCE',
    invoice_form_tva_valid: 'TVA N. not valid',
    invoice_form_siret_valid: 'SIRET not valid',
    invoice_form_destinationCode_valid: 'Destination code not valid',
    invoice_form_vat_valid: 'VAT code not valid',
    invoice_form_codice_fiscale_valid: 'Fiscal code not valid',
    codice_fiscale_uguale_partita_iva:
      'Fiscal code must be different from Vat code',
    payment_header_label: 'PAYMENT',
    stripe_header_label: 'STRIPE',
    stripe_form_name_surname: 'NAME & SURNAME',
    stripe_form_card_number: 'CARD NUMBER',
    stripe_form_expiry: 'EXPIRY',
    stripe_form_cvc: 'CVC',
    stripe_form_no_wallet: 'NO WALLET',
    stripe_form_name_surname_valid: "Name and Surname not valid - e.g. 'AA BB'",
    payment_feedback_header_title: 'THANK YOU',
    payment_feedback_success_text: 'Payment successful',
    payment_feedback_order_label: 'Order',
    payment_feedback_receipt_button_label1: 'RECEIPT',
    payment_feedback_receipt_button_label2: 'RECEIPT',
    payment_feedback_no_receipt_label: 'Receipt not available.',
    payment_feedback_receipt_generating_msg:
      'We are generating your receipt...',
    payment_feedback_survey_question1: 'How was your experience at ',
    payment_feedback_survey_question2:
      'How was your experience using the self-pay system?',
    payment_feedback_footer_button: 'GO TO HOMEPAGE',
    payment_error_header_label: 'REJECTED',
    payment_error_desc: 'Payment cancelled',
    payment_error_retry_button: 'RETRY',
    cash_system_no_response_message:
      'The payment was successful. Go to the till to collect your receipt.',
    stripe_wallet_payment_label: 'Total',
    service_not_available: 'Service not available: please try again later.',
    information: 'Information',
    residual: 'residual to pay',
    something_went_wrong: 'Something went wrong!',
    error_during: 'An error occurred during the',
    following_errors: 'The error occurred is the following:',
    problem_persist:
      'If the problem persists, you can safely retrieve the document at the cashier',
    close: 'CLOSE',
    payment_completed: 'PAYMENT COMPLETED',
    fast_payment: 'FAST PAYMENT',
    company: 'Company',
    microcompany: 'Microcompany',
    private: 'Private',
    foreign: 'Foreign',
    publicAdmin: 'Public administration',
    yes: 'Yes',
    no: 'No',
    immediata: 'Immediate',
    differita: 'Deferred',
    scissione: 'Payment splitting',
    partitaiva: 'VAT NUMBER',
    codicefiscale: 'FISCAL CODE',
    codicedestinatario: 'RECIPIENT CODE',
    riferimentoamministrazione: 'ADMINISTRATION REFERENCE',
    esigibilitaiva: 'VAT COLLECTABILITY',
    pec_mail: 'CERTIFIED E-MAIL',
    gruppoIva: 'VAT GROUP',
    partitaivacapogruppo: 'VAT NUMBER / VAT NUMBER GROUP LEADER',
    payexpert_header_label: 'PAYXPERT',
    now: 'NOW',
    province_not_valid: 'Province not valid',
    'label.pay_amount': 'Pay ',
    progress_payment: 'Payment in progress...',
    split_payment: 'Split payment',
    pay_a_part: 'Pay your part',
    pay_all: 'Pay all',
    split_analytic: 'By products',
    split_by_amount: 'Equal parts',
    split_by_share: 'How much you want to pay',
    form_field_invalid: 'Invalid field',
    total_paied: 'Total paied',
    how_many_part_pay: 'How many part you want to pay?',
    how_many_part_split: 'How many part you want to split?',
    insert_amount_want_pay: 'Insert the amount you want to pay',
    selected_min_product: 'Selected products should have minimum price of',
    unselected_min_product: 'Selected products should have minimum price of',
    select_products: 'Select products',
    payment_proof: 'Payment proof',
    no_payment_proof_remaining: 'No more payment proof remaining',
    poweredBy: 'powered by',
    cancel: 'Cancel',
    confirm: 'Confirm',
    search: 'Search',
    nessuna: 'None',
    go_cashier: 'Please, go at the cashier',
    cashsystem_does_not_respond_msg:
      'Payment received, but there was a problem',
    table_payment: 'Il pagamento al tavolo',
    complete_autonomy: 'Da fare in completa autonomia',
    simple: 'Semplice.',
    fast: 'Veloce.',
    principal_advantages: 'I principali vantaggi del pagamento al tavolo',
    simple_immediate: '4Pay è semplice e immediato.',
    scan_qr_msg: 'Scansiona il QR code a tavola e paga il tuo conto!',
    no_app_msg: 'Nessuna app',
    to_intall_msg: 'da installare',
    no_email_msg: 'Nessuna email',
    to_register: 'da registrare',
    support_payment: '4Pay supporta pagamenti',
    from_cards: 'da Carte di Credito, Apple Pay',
    google_pay: 'e Google Pay',
    safe_payment_msg: 'Pagamento sicuro',
    digital_receipt_msg: 'Scontrino digitale',
    avoid_queues_msg: 'Evita le code al punto cassa',
    insert_bill_code: 'Insert the bill code',
    fidelity_wallet_pay_button: 'Pay with wallet',
    wallet_credit: 'Wallet credit',
    wallet_member_not_active: 'Waller member not active',
    wallet_error_message: 'Payment with digital wallet failed',
  },
  fr: {
    home_view_order_label: 'Afficher commande et payer',
    home_order_now_label: 'commande',
    home_menu_label: 'Voir Menu',
    home_offers_label: 'Offert',
    footer_pay_button: 'PAYER',
    order_header_label: 'VOIR COMMANDE ET PAIEMENT',
    order_detail_total_label: 'TOTAL',
    order_detail_table_label: 'TABLE',
    order_footer_button: 'PAYER',
    checkout_header_label: 'PAYER',
    checkout_detail_discount_label: 'Remise/Rabais',
    checkout_detail_tip_label: 'POURBOIRE',
    checkout_detail_total_amount_due_label: 'MONTANT TOTAL À PAYER',
    checkout_addtip_label: 'AJOUTER UN POURBOIRE ?',
    checkout_footer_button: 'CONTINUER',
    receipt_header_label: 'REÇU',
    receipt_send_to_label: 'ENVOYER LE REÇU',
    receipt_hide_items_label: 'REPAS CONVENU',
    receipt_receive_invoice_label: 'RECEVOIR LA FACTURE',
    receipt_customer_option_label: 'SÉLECTIONNER UNE OPTION',
    receipt_email_valid: 'Courriel non valide',
    receipt_email_required: 'Le champ est obligatoire',
    form_field_required: 'Le champ est obligatoire',
    invoice_form_country: 'PAYS',
    invoice_form_france_vat_code: 'TVA',
    invoice_form_abroad_vat_code: 'Code TVA',
    invoice_form_siret_label: 'SIRET',
    invoice_form_company_label: 'NOM/ENTREPRISE',
    invoice_form_city_label: 'VILLE',
    invoice_form_zip_code_label: 'CODE POSTAL',
    invoice_form_address_label: 'ADRESSE',
    invoice_form_province_label: 'PROVINCE',
    invoice_form_tva_valid: 'TVA N. non valide',
    invoice_form_siret_valid: 'SIRET non valide',
    invoice_form_destinationCode_valid: 'Destination code non valide',
    invoice_form_vat_valid: 'VAT code non valide',
    invoice_form_codice_fiscale_valid: 'Codice fiscale non valido',
    codice_fiscale_uguale_partita_iva:
      'Fiscal code must be different from Vat code',
    payment_header_label: 'PAIEMENT',
    payment_feedback_header_title: 'MERCI',
    payment_feedback_success_text: 'Paiement réussi',
    payment_feedback_order_label: 'Commande',
    payment_feedback_receipt_button_label1: 'REÇU',
    payment_feedback_receipt_button_label2: 'REÇU',
    payment_feedback_no_receipt_label: 'Reçu non disponible.',
    payment_feedback_receipt_generating_msg: 'Nous générons votre ticket...',
    payment_feedback_survey_question1:
      "Comment s'est passée votre visite chez ",
    payment_feedback_survey_question2:
      "Comment s'est passée votre utilisation de l'app de paiement ?",
    payment_feedback_footer_button: "RETOUR PAGE D'ACCUEIL",
    payment_error_header_label: 'Rejeté',
    payment_error_desc: 'Paiement annulé',
    payment_error_retry_button: 'Réessayer',
    cash_system_no_response_message:
      'Le paiement a été effectué avec succès. Allez à la caisse pour récupérer votre reçu.',
    stripe_wallet_payment_label: 'Total',
    service_not_available:
      'Service non disponible : veuillez réessayer plus tard.',
    information: 'Information',
    residual: 'sous total à payer',
    something_went_wrong: 'Il y a eu un problème!',
    error_during: 'Une erreur est survenue durant le paiement',
    following_errors: "L'erreur survenue est la suivante:",
    problem_persist:
      'Si le problème persiste, vous pouvez récupérer le document en caisse',
    close: 'FERMER',
    payment_completed: 'PAIEMENT EFFECTUÉ',
    company: 'Société',
    microcompany: 'Microsociété',
    private: 'Particulier',
    foreign: 'Etranger',
    publicAdmin: 'Public administration',
    yes: 'Oui',
    no: 'Non',
    immediata: 'Immediata',
    differita: 'Differita',
    scissione: 'Scissione dei pagamenti',
    partitaiva: 'PARTITA IVA',
    codicefiscale: 'CODICE FISCALE',
    codicedestinatario: 'CODICE DESTINATARIO',
    riferimentoamministrazione: 'RIFERIMENTO AMMINISTRAZIONE',
    esigibilitaiva: "ESIGIBILITA' IVA",
    pec_mail: 'CERTIFIED E-MAIL',
    gruppoIva: 'GRUPPO IVA',
    partitaivacapogruppo: 'PARTITA IVA / PARTITA IVA CAPOGRUPPO',
    payexpert_header_label: 'PAYXPERT',
    now: 'MAINTENANT',
    province_not_valid: 'Province not valid',
    'label.pay_amount': 'Pay ',
    progress_payment: 'Paiement en cours...',
    split_payment: 'Split payment',
    pay_a_part: 'Pay your part',
    pay_all: 'Payer tout',
    split_analytic: 'By products',
    split_by_amount: 'Equal parts',
    split_by_share: 'How much you want to pay',
    form_field_invalid: 'Invalid field',
    total_paied: 'Total paied',
    how_many_part_pay: 'How many part you want to pay?',
    how_many_part_split: 'How many part you want to split?',
    insert_amount_want_pay: 'Insert the amount you want to pay',
    selected_min_product: 'Selected products should have minimum price of',
    unselected_min_product: 'Selected products should have minimum price of',
    select_products: 'Select products',
    payment_proof: 'Nombre repas complet',
    no_payment_proof_remaining: 'No more payment proof remaining',
    poweredBy: 'powered by',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    search: 'Recherche',
    nessuna: 'Aucun',
    go_cashier: "S'il vous plaît, allez à la caisse",
    cashsystem_does_not_respond_msg:
      'Paiement reçu, mais il y a eu un problème',
    table_payment: 'Il pagamento al tavolo',
    complete_autonomy: 'Da fare in completa autonomia',
    simple: 'Semplice.',
    fast: 'Veloce.',
    principal_advantages: 'I principali vantaggi del pagamento al tavolo',
    simple_immediate: '4Pay è semplice e immediato.',
    scan_qr_msg: 'Scansiona il QR code a tavola e paga il tuo conto!',
    no_app_msg: 'Nessuna app',
    to_intall_msg: 'da installare',
    no_email_msg: 'Nessuna email',
    to_register: 'da registrare',
    support_payment: '4Pay supporta pagamenti',
    from_cards: 'da Carte di Credito, Apple Pay',
    google_pay: ' e Google Pay',
    safe_payment_msg: 'Pagamento sicuro',
    digital_receipt_msg: 'Scontrino digitale',
    avoid_queues_msg: 'Evita le code al punto cassa',
    insert_bill_code: 'Insérez le code de facturation',
    fidelity_wallet_pay_button: 'Payer avec le portefeuille',
    wallet_credit: 'Crédit portefeuille',
    wallet_member_not_active: 'Waller membre non actif',
    wallet_error_message: 'Échec du paiement par portefeuille numérique',
  },
  it: {
    home_view_order_label: 'Il tuo conto',
    home_order_now_label: 'Ordina qui',
    home_menu_label: 'Visualizza Menu',
    home_offers_label: 'Offerto',
    footer_pay_button: 'PAGA',
    order_header_label: 'IL TUO CONTO',
    order_detail_subtotal_label: 'SUBTOTALE',
    order_detail_total_label: 'TOTALE',
    order_detail_table_label: 'TABELLA',
    order_footer_button: 'CHECKOUT',
    checkout_header_label: 'VAI AL PAGAMENTO',
    checkout_detail_discount_label: 'SCONTO',
    checkout_detail_tip_label: 'MANCIA',
    checkout_detail_total_amount_due_label: 'IMPORTO TOTALE DOVUTO',
    checkout_addtip_label: 'AGGIUNGI MANCIA?',
    checkout_footer_button: 'PROCEDI',
    receipt_header_label: 'RICEVUTA',
    receipt_send_to_label: 'INVIA RICEVUTA',
    receipt_hide_items_label: 'PASTO CONCORDATO',
    receipt_receive_invoice_label: 'RICEVI FATTURA',
    receipt_customer_option_label: "SELEZIONA UN' OPZIONE",
    receipt_email_valid: 'Email non valida',
    receipt_email_required: 'Campo obbligatorio',
    form_field_required: 'Campo obbligatorio',
    invoice_form_country: 'NAZIONE',
    invoice_form_france_vat_code: 'TVA',
    invoice_form_abroad_vat_code: 'VAT-Code',
    invoice_form_siret_label: 'SIRET',
    invoice_form_company_label: 'AZIENDA',
    invoice_form_city_label: 'CITTÀ',
    invoice_form_zip_code_label: 'CAP',
    invoice_form_address_label: 'INDIRIZZO',
    invoice_form_province_label: 'PROVINCIA',
    invoice_form_tva_valid: 'N. P.IVA non valido',
    invoice_form_siret_valid: 'SIRET non valido',
    invoice_form_destinationCode_valid: 'Codice destinatario non valido',
    invoice_form_vat_valid: 'Partita iva non valida',
    invoice_form_codice_fiscale_valid: 'Codice fiscale non valido',
    codice_fiscale_uguale_partita_iva:
      'Codice fiscale deve essere differente dalla partita iva',
    payment_header_label: 'PAGAMENTO',
    stripe_header_label: 'STRIPE',
    stripe_form_name_surname: 'NOME & COGNOME',
    stripe_form_card_number: 'NUMERO DI CARTA',
    stripe_form_expiry: 'SCADENZA',
    stripe_form_cvc: 'CVC',
    stripe_form_no_wallet: 'NO WALLET',
    stripe_form_name_surname_valid: "Nome e Cognome non validi - es. 'AA BB'",
    payment_feedback_header_title: 'GRAZIE',
    payment_feedback_success_text: 'Pagamento riuscito',
    payment_feedback_order_label: 'Ordine',
    payment_feedback_receipt_button_label1: 'Scontrino',
    payment_feedback_receipt_button_label2: 'Fattura',
    payment_feedback_no_receipt_label: 'Scontrino non disponibile.',
    payment_feedback_receipt_generating_msg:
      'Stiamo generando il tuo scontrino...',
    payment_feedback_survey_question1: 'Come è stata la tua esperienza a ',
    payment_feedback_survey_question2:
      'Come è stata la tua esperienza con il sistema di pagamento automatico?',
    payment_feedback_footer_button: 'VAI ALLA HOME PAGE',
    payment_error_header_label: 'RESPINTO',
    payment_error_desc: 'Pagamento cancellato',
    payment_error_retry_button: 'RIPROVA',
    cash_system_no_response_message:
      'Il pagamento è andato a buon fine. Vai alla cassa per ritirare lo scontrino.',
    stripe_wallet_payment_label: 'Totale',
    service_not_available: 'Servizio non disponibile: riprova più tardi.',
    information: 'Informazione',
    residual: 'residuo da pagare',
    something_went_wrong: 'Qualcosa è andato storto!',
    error_during: "E' avvenuto un errore durante il",
    following_errors: "L'errore verificatosi è il seguente:",
    problem_persist:
      'Se il problema persiste, puoi tranquillamente recuperare il documento in cassa',
    close: 'CHIUDI',
    payment_completed: 'PAGAMENTO COMPLETATO',
    fast_payment: 'PAGAMENTO VELOCE',
    company: 'Azienda',
    microcompany: 'Micro azienda',
    private: 'Privato',
    foreign: 'Straniero',
    publicAdmin: 'Pubblica amministrazione',
    yes: 'Si',
    no: 'No',
    immediata: 'Immediata',
    differita: 'Differita',
    scissione: 'Scissione dei pagamenti',
    partitaiva: 'PARTITA IVA',
    codicefiscale: 'CODICE FISCALE',
    codicedestinatario: 'CODICE DESTINATARIO',
    riferimentoamministrazione: 'RIFERIMENTO AMMINISTRAZIONE',
    esigibilitaiva: "ESIGIBILITA' IVA",
    pec_mail: 'PEC',
    gruppoIva: 'GRUPPO IVA',
    partitaivacapogruppo: 'PARTITA IVA / PARTITA IVA CAPOGRUPPO',
    payexpert_header_label: 'PAYXPERT',
    now: 'ORA',
    province_not_valid: 'Provincia non valida',
    'label.pay_amount': 'Paga ',
    progress_payment: 'Pagamento in corso...',
    split_payment: "Dividi l'importo",
    pay_a_part: 'Paga la tua parte',
    pay_all: 'Vai al pagamento',
    split_analytic: 'Per prodotti',
    split_by_amount: 'Parti uguali',
    split_by_share: 'Per importo',
    form_field_invalid: 'Campo non valido',
    total_paied: 'Totale pagato',
    how_many_part_pay: 'Quante parti vuoi pagare?',
    how_many_part_split: 'In quante parte vuoi dividere?',
    insert_amount_want_pay: "Inserisci l'importo che vuoi pagare",
    selected_min_product: 'Selected products should have minimum price of',
    unselected_min_product: 'Selected products should have minimum price of',
    select_products: 'Select products',
    payment_proof: 'Giustificativi',
    no_payment_proof_remaining: 'Non ci sono giustificativi rimasti',
    poweredBy: 'powered by',
    cancel: 'Annulla',
    confirm: 'Conferma',
    search: 'Cerca',
    nessuna: 'Nessuna',
    go_cashier: 'Per favore , recarsi alla cassa',
    cashsystem_does_not_respond_msg:
      'Pagamento ricevuto, ma si è verificato un problema',
    table_payment: 'Il pagamento al tavolo',
    complete_autonomy: 'Da fare in completa autonomia',
    simple: 'Semplice.',
    fast: 'Veloce.',
    principal_advantages: 'I principali vantaggi del pagamento al tavolo',
    simple_immediate: '4Pay è semplice e immediato.',
    scan_qr_msg: 'Scansiona il QR code a tavola e paga il tuo conto!',
    no_app_msg: 'Nessuna app',
    to_intall_msg: 'da installare',
    no_email_msg: 'Nessuna email',
    to_register: 'da registrare',
    support_payment: '4Pay supporta pagamenti',
    from_cards: 'da Carte di Credito, Apple Pay',
    google_pay: 'e Google Pay',
    safe_payment_msg: 'Pagamento sicuro',
    digital_receipt_msg: 'Scontrino digitale',
    avoid_queues_msg: 'Evita le code al punto cassa',
    insert_bill_code: 'Inserisci il codice dello scontrino',
    fidelity_wallet_pay_button: 'Paga con portafoglio virtuale',
    wallet_credit: 'Credito portafoglio',
    wallet_member_not_active: 'Utente non attivo',
    wallet_error_message: 'Pagamento con portafoglio digitale non riuscito',
  },
  de: {
    home_view_order_label: 'Ihre Rechnung',
    home_order_now_label: 'Hier bestellen',
    home_menu_label: 'Menü anzeigen',
    home_offers_label: 'Gratis',
    footer_pay_button: 'BEZAHLEN',
    order_header_label: 'IHRE RECHNUNG',
    order_detail_subtotal_label: 'ZWISCHENSUMME',
    order_detail_total_label: 'GESAMT',
    order_detail_table_label: 'TABELLE',
    order_footer_button: 'CHECKOUT',
    checkout_header_label: 'ZUR BEZAHLUNG',
    checkout_detail_discount_label: 'RABATT',
    checkout_detail_tip_label: 'TRINKGELD',
    checkout_detail_total_amount_due_label: 'FÄLLIGER GESAMTBETRAG',
    checkout_addtip_label: 'TRINKGELD HINZUFÜGEN?',
    checkout_footer_button: 'FORTFAHREN',
    receipt_header_label: 'KASSENBELEG',
    receipt_send_to_label: 'KASSENBELEG SENDEN',
    receipt_hide_items_label: 'VEREINBARTES ESSEN',
    receipt_receive_invoice_label: 'RECHNUNG ERHALTEN',
    receipt_customer_option_label: 'EINE OPTION WÄHLEN',
    receipt_email_valid: 'Ungültige E-Mail',
    receipt_email_required: 'Pflichtfeld',
    form_field_required: 'Pflichtfeld',
    invoice_form_country: 'NATION',
    invoice_form_france_vat_code: 'TVA',
    invoice_form_abroad_vat_code: 'VAT-Code',
    invoice_form_siret_label: 'SIRET',
    invoice_form_company_label: 'UNTERNEHMEN',
    invoice_form_city_label: 'STADT',
    invoice_form_zip_code_label: 'PLZ',
    invoice_form_address_label: 'ADRESSE',
    invoice_form_province_label: 'PROVINZ',
    invoice_form_tva_valid: 'Ungültige USt-IdNr.',
    invoice_form_siret_valid: 'SIRET ungültig',
    invoice_form_destinationCode_valid: 'Ungültiger Empfänger-Code',
    invoice_form_vat_valid: 'USt-IdNr. ungültig',
    invoice_form_codice_fiscale_valid: 'Ungültige Steuernummer',
    codice_fiscale_uguale_partita_iva:
      'Die Steuernummer muss sich von der USt-IdNr. unterscheiden',
    payment_header_label: 'BEZAHLUNG',
    stripe_header_label: 'STRIPE',
    stripe_form_name_surname: 'VORNAME & NACHNAME',
    stripe_form_card_number: 'KARTENNUMMER',
    stripe_form_expiry: 'GÜLTIG BIS',
    stripe_form_cvc: 'CVC',
    stripe_form_no_wallet: 'NO WALLET',
    stripe_form_name_surname_valid:
      'Ungültiger Vor- und Nachname - z.B. „AA BB“',
    payment_feedback_header_title: 'VIELEN DANK',
    payment_feedback_success_text: 'Zahlung abgeschlossen',
    payment_feedback_order_label: 'Bestellung',
    payment_feedback_receipt_button_label1: 'Quittung',
    payment_feedback_receipt_button_label2: 'Rechnung',
    payment_feedback_no_receipt_label: 'Quittung nicht verfügbar.',
    payment_feedback_receipt_generating_msg: 'Ihre Quittung wird erstellt...',
    payment_feedback_survey_question1: 'Wie war Ihre Erfahrung bei ',
    payment_feedback_survey_question2:
      'Wie war Ihre Erfahrung mit dem automatischen Zahlungssystem?',
    payment_feedback_footer_button: 'ZUR HOMEPAGE GEHEN',
    payment_error_header_label: 'ABGELEHNT',
    payment_error_desc: 'Zahlung storniert',
    payment_error_retry_button: 'ERNEUT VERSUCHEN',
    cash_system_no_response_message:
      'Die Zahlung war erfolgreich. Gehen Sie zur Kasse, um Ihre Quittung abzuholen.',
    stripe_wallet_payment_label: 'Gesamt',
    service_not_available:
      'Dienst nicht verfügbar: Bitte versuchen Sie es später erneut.',
    information: 'Information',
    residual: 'zu zahlender Restbetrag',
    something_went_wrong: 'Irgendetwas ist schief gelaufen!',
    error_during: 'Ein Fehler ist aufgetreten während des/der',
    following_errors: 'Folgender Fehler ist aufgetreten:',
    problem_persist:
      'Wenn das Problem weiterhin besteht, können Sie das Dokument problemlos bei der Kasse erhalten',
    close: 'SCHLIESSEN',
    payment_completed: 'ZAHLUNG ABGESCHLOSSEN',
    fast_payment: 'SCHNELLES BEZAHLEN',
    company: 'Unternehmen',
    microcompany: 'Kleinunternehmen',
    private: 'Privat',
    foreign: 'Ausländisch',
    publicAdmin: 'Öffentliche Verwaltung',
    yes: 'Ja',
    no: 'Nein',
    immediata: 'Unmittelbar',
    differita: 'Aufgeschoben',
    scissione: 'Aufteilung der Zahlungen',
    partitaiva: 'UST-IDNR.',
    codicefiscale: 'STEUERNUMMER',
    codicedestinatario: 'EMPFÄNGER-CODE',
    riferimentoamministrazione: 'VERWALTUNGSREFERENZ',
    esigibilitaiva: 'UST-FÄLLIGKEIT',
    pec_mail: 'ZERTIFIZIERTE E-MAIL',
    gruppoIva: 'UST-GRUPPE',
    partitaivacapogruppo: 'UST-IDNR. / UST-IDNR. GRUPPENLEITER',
    payexpert_header_label: 'PAYXPERT',
    now: 'UHRZEIT',
    province_not_valid: 'Ungültige Provinz',
    'label.pay_amount': 'Bezahlen ',
    progress_payment: 'Zahlung läuft...',
    split_payment: 'Betrag aufteilen',
    pay_a_part: 'Zahlen Sie Ihren Anteil',
    pay_all: 'Zur Bezahlung',
    split_analytic: 'Für Produkte',
    split_by_amount: 'Gleiche Teile',
    split_by_share: 'Für Betrag',
    form_field_invalid: 'Ungültiges Feld',
    total_paied: 'Bezahlter Gesamtbetrag',
    how_many_part_pay: 'Wie viele Teile möchten Sie bezahlen?',
    how_many_part_split: 'In wie viele Teile möchten Sie aufteilen?',
    insert_amount_want_pay: 'Geben Sie den Betrag ein, den Sie zahlen möchten',
    selected_min_product:
      'Die ausgewählten Produkte sollten einen Mindestpreis haben von',
    unselected_min_product:
      'Die ausgewählten Produkte sollten einen Mindestpreis haben von',
    select_products: 'Produkte auswählen',
    payment_proof: 'Belege',
    no_payment_proof_remaining: 'Es sind keine Belege mehr vorhanden',
    poweredBy: 'powered by',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    search: 'Suchen',
    nessuna: 'Kein(e)',
    go_cashier: 'Bitte gehen Sie zur Kasse',
    cashsystem_does_not_respond_msg:
      'Zahlung erhalten, aber es ist ein Problem aufgetreten',
    insert_bill_code: 'Geben Sie den Rechnungscode ein',
    fidelity_wallet_pay_button: 'Bezahlen mit Brieftasche',
    wallet_credit: 'Portfoliokredit',
    wallet_member_not_active: 'Waller Mitglied nicht aktiv',
    wallet_error_message: 'Zahlung mit digitaler Brieftasche fehlgeschlagen',
  },
};
