import { CurrencyTypeDirective } from './directives/currency-type.directive';
import { CssCustomDirective } from './directives/css-custom.directive';
import { IfCustomerTypeDirective } from './directives/if-customer-type.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UrlDirective } from './directives/url.directive';
import { ItemColorDarkDirective } from './directives/item-color-dark.directive';
import { FocusFirstInvalidFieldDirective } from './directives/focus-first-invalid-field.directive';
import { PaymentInvoiceErrorFieldDirective } from './directives/payment-invoice-error-field.directive';
import { IfPaymentInvoiceErrorDirective } from './directives/if-payment-invoice-error.directive';

const DIRECTIVES = [
  UrlDirective,
  IfCustomerTypeDirective,
  ItemColorDarkDirective,
  CssCustomDirective,
  FocusFirstInvalidFieldDirective,
  PaymentInvoiceErrorFieldDirective,
  IfPaymentInvoiceErrorDirective,
  CurrencyTypeDirective
];
@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES]
})
export class DirectivesModule {}
