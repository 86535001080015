export enum CustomerType {
  CUSTOMER1 = 'Company',
  CUSTOMER2 = 'Micro Company',
  CUSTOMER3 = 'Private',
  CUSTOMER4 = 'Foreign',
  CUSTOMER5 = 'Pubblica ammin',
  CUSTOMER6 = 'Azienda',
  CUSTOMER7 = 'Privato',
}
export interface InvoiceFR {
  country: string;
  nation?: string;
  tva: string;
  siret: string;
  company: string;
  full_name?: string;
  city: string;
  zip: string;
  cap?: string;
  address: string;
  type?: string;
  customer_type: 'company' | 'microcompany' | 'private' | 'foreign';
  country_code: string;
  email?: string;
  naf?: string;
}

export interface InvoiceIT {
  country: string;
  nation?: string;
  company: string;
  full_name?: string;
  city?: string;
  zip?: string;
  cap?: string;
  address?: string;
  type?: string;
  customer_type: 'pubblicaammin' | 'azienda' | 'privato';
  country_code: string;
  email?: string;
  codice_sdi?: string;
  pec_mail?: string;
  partita_iva?: string;
  partita_iva_capogruppo?: string;
  gruppo_iva?: string;
  codice_fiscale?: string;
  rif_amministrazione?: string;
  esigibilita_iva?: string;
  provincia?: string;
}

export type PopupoverStatus = 'open' | 'close' | 'select';
