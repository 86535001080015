/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/member-ordering */
import { InvoiceFR, InvoiceIT } from './../../models/invoice';
import {
  noSpaceAndLowercaseFr,
  noSpaceAndLowercaseIt,
} from './../../utility/no-space';
import {
  OrderBody,
  OrderBodyShare,
  PaymentMode,
  PaymentRequestFR,
  PaymentRequestIT,
} from './../../models/payment_request';
import { Message } from 'src/app/models/message';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { SurveyRequest } from './../../models/survey';
import { ConfigStyles, Style } from 'src/app/models/config';
import { WebSocketService } from './websocket.service';
import { Receipt } from './../../models/receipt';
import { OrderStore } from './../stores/order.store';
import { PaymentStore } from 'src/app/core/stores/payment.store';
import { Checkout } from './../../models/checkout';
/* eslint-disable max-len */
import {
  catchError,
  concatMap,
  delay,
  map,
  retry,
  take,
  switchMap,
  filter,
  tap,
} from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { Auth } from 'src/app/models/auth';
import { Guest } from 'src/app/models/guest';
import { Order } from 'src/app/models/order';
import { Restaurant } from 'src/app/models/restaurant';
import { environment as env } from 'src/environments/environment';
import { HttpHandlerErrorService } from './http-handler-error.service';
import { ConfigStore } from '../stores/config.store';
import { CheckoutStore } from '../stores/checkout.store';
import { OrderItem } from 'src/app/models/order-item';
import { PaymentCompleteResponse } from 'src/app/models/payment_complete_response';

@Injectable({ providedIn: 'root' })
export class ApiServeice {
  // startPaymentRequest: PaymentRequest | null = null;
  configStore$: Observable<Restaurant | null> = this.configStore.restaurant$;

  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService,
    private configStore: ConfigStore,
    private orderStore: OrderStore,
    private checkoutStore: CheckoutStore,
    private paymentStore: PaymentStore,
    private ws: WebSocketService
  ) {}

  body = (): {
    restourant_uuid: string;
    table_uuid: string;
    order_id: number;
    payment_mode: PaymentMode;
    amount_to_pay: number;
    total_parts?: number;
    parts_to_pay?: number;
  } => {
    let amountToPay = 0;
    let paymentMode: PaymentMode = 0;
    let partsToPay = 0;
    let totalParts = 0;
    const splitPaymentData = this.orderStore.getSplitPayment();
    if (splitPaymentData?.splitMode) {
      paymentMode = +splitPaymentData?.splitMode as PaymentMode;
    }
    if (
      splitPaymentData?.splitAmount &&
      (paymentMode === 0 || paymentMode === 1)
    ) {
      amountToPay = splitPaymentData?.splitAmount;
    }
    if (splitPaymentData?.total_parts) {
      totalParts = splitPaymentData?.total_parts;
    }
    if (splitPaymentData?.parts_to_pay) {
      partsToPay = splitPaymentData?.parts_to_pay;
    }
    const body = {
      restourant_uuid: this.auth().restaurantUUID,
      table_uuid: this.auth().tableUUID,
      order_id: this.guest().order_id,
      payment_mode: paymentMode,
      amount_to_pay: amountToPay,
      total_parts: totalParts,
      parts_to_pay: partsToPay,
    };

    return body;
  };

  auth = (): Auth => {
    const authStorage = localStorage.getItem('auth');
    return authStorage ? JSON.parse(authStorage) : null;
  };

  guest = (): Guest => {
    const guestStorage = localStorage.getItem('guest');
    return guestStorage ? JSON.parse(guestStorage) : null;
  };

  receipt = (): Receipt => {
    const receiptStorage = localStorage.getItem('receipt');
    return receiptStorage ? JSON.parse(receiptStorage) : null;
  };

  invoiceFR = (): InvoiceFR => {
    const invoiceStoraged = localStorage.getItem('invoice');
    return invoiceStoraged ? JSON.parse(invoiceStoraged) : null;
  };

  invoiceIT = (): InvoiceIT => {
    const invoiceStoraged = localStorage.getItem('invoice');
    return invoiceStoraged ? JSON.parse(invoiceStoraged) : null;
  };

  options = () => ({
    params: new HttpParams()
      .append('restourant_uuid', this.auth().restaurantUUID)
      .append('table_uuid', this.auth().tableUUID)
      .append('order_id', this.guest().order_id),
  });

  cleanLocalStorageExcept(keys: string[] | null): void {
    if (keys) {
      const list = Object.keys(localStorage);
      list.forEach((key) =>
        !keys.includes(key) ? localStorage.removeItem(key) : key
      );
    } else {
      localStorage.clear();
    }
  }

  /**
   *
   * @param restaurantUUID unique ID for a specific restaurant
   * @param tableUUID  unique ID for a specific table
   * @param fastpay 1 true 0 false
   * @returns
   */
  guestLogin(
    restaurantUUID: string,
    tableUUID: string,
    fastPayEnabled: string | null
  ): Observable<Guest> {
    this.cleanLocalStorageExcept(env.keysToLeft);
    localStorage.setItem(
      'auth',
      JSON.stringify({
        restaurantUUID,
        tableUUID,
        fastPayEnabled:
          fastPayEnabled && fastPayEnabled.split(' ')[0] === '1' ? true : false,
      })
    );
    const body = { restourant_uuid: restaurantUUID, table_uuid: tableUUID };
    return this.http
      .post<Restaurant>(env.host + 'api/restourant_token', body)
      .pipe(
        concatMap((data) => {
          let config: ConfigStyles | null;
          const config_styles = data.configuration_styles;
          Object.keys(config_styles).length === 0 || config_styles === null
            ? (config = null)
            : (config = config_styles);
          this.configStore.dispatchSetRestaurantData(data); // set restaurant data
          this.configStore.dispatchInitConfig(config); // initialize the config from cloudoffice for a specific restaurant
          // update the auth object also with stripeKey
          const auth = this.auth();
          localStorage.setItem(
            'auth',
            JSON.stringify({
              ...auth,
              stripeKey: data.configuration.stripe_key,
            })
          );
          return this.getGuest(data);
        }),
        // retry(3), // retry a failed request up to 3 times
        catchError((error) => this.errorHandler.handleError(error))
      );
  }

  setCurrencySymbol(currency: string = 'eur') {
    let symbol = '€';
    switch (currency.toLowerCase()) {
      case 'usd':
        symbol = '$ ';
        break;
      case 'chf':
        symbol = '₣ ';
        break;
      default:
        symbol = '€ ';
        break;
    }
    return symbol;
  }

  getGuest(data: Restaurant): Observable<Guest> {
    const { guest_token, configuration } = data;
    // save into the store the config of the Restaurant
    // configuraton for CloudOffice
    // this.configStore.dispatchSetConfig(configuration || null);
    const splitPaymentEnabled =
      !!configuration.splitting?.split_analytic ||
      !!configuration.splitting?.split_by_amount ||
      !!configuration.splitting?.split_by_share;
    const body = {
      restourant_uuid: this.auth().restaurantUUID,
      guest_token,
      table_uuid: this.auth().tableUUID,
      fast_pay: this.auth().fastPayEnabled,
    };

    return this.http.post<Guest>(env.host + 'api/guest/get_guest', body).pipe(
      map((guest) => ({
        ...guest,
        fast_pay_enabled: this.auth().fastPayEnabled
          ? guest.fast_pay_enabled
          : false,
        split_payment_enabled: splitPaymentEnabled,
        digital_wallet: configuration.digital_wallet,
        pay_with_digital_wallet: configuration.pay_with_digital_wallet,
        currency: configuration?.currency ?? 'eur',
        currency_symbol: this.setCurrencySymbol(
          configuration?.currency ?? 'eur'
        ),
      }))
      // retry(3),
      // delay(1000)
    );
  }
  /**
   * request Order Content Redis to get the order without tips
   *
   * @returns
   */
  requestOrderContentRedis(): Observable<{
    id: number;
    order: Order[];
  } | null> {
    return this.http
      .post<{ id: number; order: Order[] }>(
        `${env.host}api/guest/request_order_content_redis`,
        this.body()
      )
      .pipe(
        catchError((e: any) => {
          this.orderStore.dispatchEmptyOrder();
          this.ws.leaveOrderContentChannel(this.guest().order_id); // leave channel
          this.errorHandler.orderErrorPopup(e);
          return EMPTY;
        })
      );
  }

  /**
   * request Start Checkout to get the order with tips
   *
   * @returns
   */
  requestStartCheckout(): Observable<any> {
    return this.http
      .post<any>(`${env.host}api/guest/request_start_checkout`, this.body())
      .pipe(
        catchError((e: any) => {
          this.checkoutStore.dispatchEmptyCheckout();
          this.ws.leaveStartCheckoutChannel(this.guest().order_id); // leave channel
          this.errorHandler.checkoutErrorPopup(e);
          return EMPTY;
        })
      );
  }

  /**
   * request Fast Payment to get the order with tips
   *
   * @returns
   */
  requestFastPayment(): Observable<any> {
    return this.http
      .post<any>(`${env.host}api/guest/request_fast_payment`, this.body())
      .pipe(
        catchError((e: any) => {
          this.checkoutStore.dispatchEmptyCheckout();
          this.orderStore.dispatchResetSplitPayment();
          this.ws.leaveStartFastPaymentChannel(this.guest().order_id); // leave channel
          this.errorHandler.fastPayCheckoutErrorPopup(e);
          return EMPTY;
        })
      );
  }

  /**
   * Start Payment Process
   *
   * @returns
   */
  requestStartPaymentProcess(): Observable<Message> {
    console.log('req StartPaymentProcess');
    return this.checkoutStore.startPaymentCheckout$.pipe(
      filter((checkout): checkout is Checkout => !!checkout),
      // take(1),
      switchMap((checkout) =>
        this.http
          .post<any>(
            `${env.host}api/guest/request_start_payment_process`,
            this.createPaymentRequestObject(checkout)
          )
          .pipe(
            tap(({ message }) => console.log('res', message)),
            map(({ message }) => {
              const mes: Message = { type: 'success', message };
              return mes;
            }),
            catchError((e: any) => {
              console.log('catchError request_start_payment_process');
              this.paymentStore.dispatchStopPaymentProcess();
              this.ws.leaveStartPaymentProcess(this.guest().order_id); // leave channel
              this.errorHandler.requestStartPaymentProcessErrorPopup(e);
              return EMPTY;
              // const message: Message = { type: 'error', message: e.message };
              // return of(message);
            })
          )
      )
    );
  }

  createPaymentRequestObject(
    checkout: Checkout
  ): PaymentRequestFR | PaymentRequestIT | null {
    try {
      let paymentReq = null;
      let country = '';
      let paymentMode: PaymentMode = 0;
      let partsToPay = 0;
      let totalParts = 0;
      let splitAmount = 0;
      this.configStore$
        .pipe(take(1))
        .subscribe((restaurant) => (country = restaurant?.country || ''));
      const splitPaymentData = this.orderStore.getSplitPayment();
      if (splitPaymentData?.splitAmount) {
        splitAmount = splitPaymentData?.splitAmount;
      }
      if (splitPaymentData?.splitMode) {
        paymentMode = +splitPaymentData?.splitMode as PaymentMode;
      }
      if (splitPaymentData?.parts_to_pay) {
        partsToPay = splitPaymentData.parts_to_pay;
      }
      if (splitPaymentData?.total_parts) {
        totalParts = splitPaymentData.total_parts;
      }
      switch (country.toUpperCase()) {
        case 'IT':
          paymentReq = this.buildITPayment(
            checkout,
            paymentMode,
            partsToPay,
            totalParts,
            splitAmount
          );
          break;
        case 'FR':
          paymentReq = this.buildFRPayment(
            checkout,
            paymentMode,
            partsToPay,
            totalParts,
            splitAmount
          );
          break;
        default:
          paymentReq = this.buildFRPayment(
            checkout,
            paymentMode,
            partsToPay,
            totalParts,
            splitAmount
          );
          break;
      }
      // console.log('payment request', reqWithFRProperties);
      // create a new item 'payment' in localstorage
      localStorage.setItem('payment', JSON.stringify(paymentReq));
      this.paymentStore.dispatchSetPaymentRequest(paymentReq); // save into the store
      return paymentReq;
    } catch (error) {
      return null;
    }
  }

  requestPayWithGateway(payment_intent_param: string): Observable<any> {
    const body = {
      payment_intent: payment_intent_param,
      ...this.body(),
      status: 'succeeded',
    };
    return this.http
      .post<any>(`${env.host}api/guest/request_pay_with_gateway`, body)
      .pipe(
        map((res) => ({ status: 200, message: res })),
        retry(2),
        catchError((e: any) => {
          this.paymentStore.dispatchStartPayment(false);
          return this.errorHandler.handleError(e);
        })
      );
  }

  /**
   * request PaymentCompleteProcess to get the invoice doc url
   *
   * @returns
   */
  requestPaymentCompletedProcess(): Observable<any> {
    console.log('request payment completed');
    return this.http
      .post<any>(`${env.host}api/guest/request_payment_completed`, this.body())
      .pipe(
        map((res) => {
          console.log('res', res);
          return { status: 200, message: res as PaymentCompleteResponse };
        }),
        catchError((e: any) => {
          this.errorHandler.requestPaymentCompletedProcessErrorPopup(e);
          return EMPTY;
        })
      );
  }

  /**
   * request abort process
   *
   * @returns
   */
  requestAbortProcess(): Observable<any> {
    console.log('request abort process', this.body());
    return this.http
      .post<any>(`${env.host}api/guest/request_abort_process`, this.body())
      .pipe(catchError((e: any) => this.errorHandler.handleError(e)));
  }

  requestReceipt(
    token: string,
    restourant_uuid: string,
    order_id: number
  ): void {
    window.open(
      `${env.host}api/request_receipt?auth_token=${token}&order_id=${order_id}&restourant_uuid=${restourant_uuid}`
    );
  }

  requestRestaurantExperience(preference: SurveyRequest): Observable<any> {
    const { survey_1, survey_2 } = preference;
    const body = {
      ...this.body(),
      survey_1,
      survey_2,
    };
    return this.http
      .post<any>(`${env.host}api/guest/store_payment_survey_response`, body)
      .pipe(catchError((e: any) => this.errorHandler.handleError(e)));
  }

  buildFRPayment(
    checkout: any,
    paymentMode: PaymentMode,
    partsToPay: number,
    totalParts: number,
    splitAmount: number
  ) {
    const body: PaymentRequestFR = {
      ...this.body(),
      order_amount: splitAmount ? splitAmount : checkout.amount_residual, // amount in cents
      tips_amount: checkout.tip_amount ? checkout.tip_amount : 0, // tip in cent
      parts_to_pay: partsToPay,
      total_parts: totalParts,
      payment_mode: paymentMode,
      payment_proof_requested: this.receipt()
        ? this.receipt().payment_proof_requested
        : false,
      invoice: checkout.customer_invoice ? true : false,
      order_body:
        paymentMode === 2 ? this.orderBodyMapper(checkout.products) : [],
      payment_proof_parts: this.receipt()?.payment_proof_parts ?? 0,
    };
    // check if email exist
    const reqWithEmail: PaymentRequestFR = checkout.send_receipt_to
      ? { ...body, mail_receipt_to: checkout.send_receipt_to }
      : { ...body };
    if (checkout.customer_invoice) {
      // check if invoice is required
      const {
        address,
        zip: cap,
        city,
        company: full_name,
        country_code: nation,
      } = this.invoiceFR();
      const reqWithInvoice: PaymentRequestFR = checkout.customer_invoice
        ? {
            ...reqWithEmail,
            customer_invoice: {
              address,
              cap,
              city,
              full_name,
              nation,
              customer_type: noSpaceAndLowercaseFr(
                this.invoiceFR().type
              ) as any,
            },
          }
        : { ...reqWithEmail };
      // check if nation is FR
      const reqWithFRProperties: PaymentRequestFR =
        this.invoiceFR().country_code === 'FR'
          ? {
              ...reqWithInvoice,
              customer_invoice: {
                address,
                cap,
                city,
                full_name,
                nation,
                naf: '',
                tva: this.invoiceFR().tva,
                siret: this.invoiceFR().siret,
                customer_type: noSpaceAndLowercaseFr(
                  this.invoiceFR().type
                ) as any,
              },
            }
          : { ...reqWithInvoice };
      return reqWithFRProperties;
    }
    return reqWithEmail;
  }

  buildITPayment(
    checkout: any,
    paymentMode: PaymentMode,
    partsToPay: number,
    totalParts: number,
    splitAmount: number
  ) {
    const body: PaymentRequestIT = {
      ...this.body(),
      order_amount: splitAmount ? splitAmount : checkout.amount_residual,
      tips_amount: checkout.tip_amount ? checkout.tip_amount : 0, // tip in cent
      parts_to_pay: partsToPay,
      payment_mode: paymentMode,
      total_parts: totalParts,
      payment_proof_requested: this.receipt()
        ? this.receipt().payment_proof_requested
        : false,
      invoice: checkout.customer_invoice ? true : false,
      order_body:
        paymentMode === 2 ? this.orderBodyMapper(checkout.products) : [],
      payment_proof_parts: this.receipt()?.payment_proof_parts ?? 0,
    };
    // check if email exist
    const reqWithEmail: PaymentRequestIT = checkout.send_receipt_to
      ? { ...body, mail_receipt_to: checkout.send_receipt_to }
      : { ...body };
    if (checkout.customer_invoice) {
      // check if invoice is required
      const {
        address,
        zip: cap,
        city,
        company: full_name,
        country_code: nation,
      } = this.invoiceIT();
      const reqWithITProperties: PaymentRequestIT = {
        ...reqWithEmail,
        customer_invoice: {
          pubblica_ammin:
            this.invoiceIT().country_code === 'IT' &&
            checkout.customer_invoice.type === 'Pubblica ammin'
              ? true
              : false,
          address,
          cap,
          city,
          full_name,
          nation,
          ...checkout.customer_invoice,
          gruppo_iva:
            checkout.customer_invoice.gruppo_iva === 'si' ? true : false,
          customer_type: noSpaceAndLowercaseIt(this.invoiceIT().type) as any,
        },
      };
      console.log('reqWithITProperties', reqWithITProperties);
      return reqWithITProperties;
    }
    return reqWithEmail;
  }

  /**
   * request Fast Payment to get the order with tips
   *
   * @returns
   */
  requestTips(curAmount: number): Observable<any> {
    const body = {
      restourant_uuid: this.body().restourant_uuid,
      amount: Math.round(curAmount),
    };
    return this.http
      .post<any>(`${env.host}api/guest/request_tips`, body)
      .pipe(catchError((e: any) => this.errorHandler.handleError(e)));
  }

  orderBodyMapper(products: OrderItem[]): OrderBody[] {
    console.log('orderBodyMapper', products);
    const orderBodyResult: OrderBody[] = [];
    products.forEach((element) => {
      const orderBody = {} as OrderBody;
      orderBody.rowId = +element.id;
      orderBody.qty = element.quantity;
      orderBody.rowLinkMask = element.row_link_mask;
      if (element.shareable) {
        const share = {} as OrderBodyShare;
        share.totalParts = element.shared?.total_parts ?? 0;
        share.paidParts = element.shared?.paid_parts ?? 0;
        share.partsToPay = element.shared?.currentPayment?.parts ?? 0;
        share.amountToPay = element.shared?.currentPayment?.amount ?? 0;
        orderBody.share = share;
      }
      orderBodyResult.push(orderBody);
    });
    return orderBodyResult;
  }

  login4Loyalty(user: string, pwd: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Accept', 'application/json');

    let account_id = '';
    this.configStore.restaurant$.pipe(take(1)).subscribe((restaurant) => {
      if (restaurant?.account_id) {
        account_id = restaurant?.account_id;
      }
    });

    const body = {
      grant_type: 'password_override',
      client_id: 1,
      client_secret: '6VUdS4lO2UPXa0jqmZMngeoWIxDM3CKR88nwnOVm',
      site_id: account_id,
      username: user,
      password: pwd,
      scope: '',
    };

    return this.http
      .post<any>(`${env.fidelityUrl}app/v1/login`, body, { headers: headers })
      .pipe(
        map((res) => {
          const response = { status: 200, message: res as any };
          return response;
        }),
        catchError((e: any) => this.errorHandler.handleError4Loyalty(e))
      );
  }

  getLoyaltyMember(
    member_id: number | null,
    access_token: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ${access_token}`);

    return this.http
      .get<any>(`${env.fidelityUrl}app/v1/` + member_id, { headers: headers })
      .pipe(
        map((res) => {
          const response = { status: 200, member_data: res as any };
          return response;
        }),
        catchError((e: any) => this.errorHandler.handleError4Loyalty(e))
      );
  }

  requestPayWithDigitalWallet(
    paymentIntent: string,
    member_data: any
  ): Observable<any> {
    const body = {
      order_id: this.guest().order_id,
      payment_intent: paymentIntent,
      restourant_uuid: this.auth().restaurantUUID,
      table_uuid: this.auth().tableUUID,
      status: 'succeeded',
      member: member_data,
    };
    return this.http
      .post<any>(`${env.host}api/guest/request_pay_with_digital_wallet`, body)
      .pipe(
        map((res) => ({ status: 200, message: res })),
        catchError((e: any) => {
          this.paymentStore.dispatchStartWalletPayment(false);
          return this.errorHandler.handleError(e);
        })
      );
  }

  // requestSelfPayExperience(preference: Survey | null): Observable<any> {
  //   const body = {
  //     ...this.body(),
  //     survey_1: preference
  //   };
  //   return this.http.post<any>(`${env.host}api/guest/store_payment_survey_response`, body, this.httpHeaders)
  //   .pipe(
  //     catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
  //   );
  // }
}
