import { take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Language } from 'src/app/models/language';
import { interval, of, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  language: Language = environment.language as Language;
  default: Language = 'en';
  translations: { [key: string]: any } | null = null;
  setLanguage(lang: Language): void {
    this.language = lang;
  }

  initTranslations(): void {
    // simulate
    console.log('INITIALIZE LANGUAGE', this.language);
    of(true)
      .pipe(
        // of needs only for the simulation
        switchMap(() =>
          interval() // replace interval with api endpoint
            .pipe(
              tap(console.log),
              take(1),
              // map(() => (
              //   {
              //     en: {
              //       home_view_order_label : 'Mostra Ordine e Paga',
              //       home_order_now_label : 'Ordina Ora',
              //       home_menu_label: 'View Menu',
              //       home_offers_label: 'Offers',
              //     }
              //   }
              // )),
              map(() => null)
            )
        ),
        tap(console.log)
      )
      .subscribe(
        (res: { [key: string]: string } | null) => (this.translations = res)
      );
  }
}
