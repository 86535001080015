
<div class="general-error-overlay"></div>
<div class="general-error-content">
  <ion-label class="general-error-label-head">{{ 'something_went_wrong' | translate }}</ion-label>
  <ion-img class="general-error-warning-img" [src]="'/assets/icon/svg/4pay_icon_warning.svg'"></ion-img>
  <div class="general-error-errors-list" *ngIf="!cashsystem_does_not_respond_msg; else cashSystemNotRespond">
    <ion-label>{{ 'following_errors' | translate }}</ion-label>
    <ion-label class="l4pay-margin-top05" *ngFor="let error of errors"> {{ error.desc }} </ion-label>
    <ion-label *ngIf="noErrors" class="l4pay-margin-top05"> {{ 'service_not_available' | translate}} </ion-label>
    <ion-label class="l4pay-margin-top">{{ 'problem_persist' | translate }}</ion-label>
  </div>
  <ng-template #cashSystemNotRespond>
    <div class="general-error-errors-list">
      <ion-label>{{ 'following_errors' | translate }}</ion-label>
      <ion-label *ngIf="cashsystem_does_not_respond_msg" class="l4pay-margin-top05"> {{ 'cashsystem_does_not_respond_msg' | translate}} </ion-label>
      <ion-label class="l4pay-margin-top">{{ 'go_cashier' | translate }}</ion-label>
    </div>
  </ng-template>

  <ion-button style="margin-top: 1rem; margin-bottom: 1rem; min-height: 3rem;" (click)="dismissModal()" color="primary" size="large">{{ 'close' | translate }}</ion-button>
</div>


