import { Checkout } from 'src/app/models/checkout';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Amount } from 'src/app/models/amount';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-toolbar-order-detail',
  template: `
    <ion-toolbar class="ion-no-border toolbar-order-detail" color="light">
      <div class="row l4pay-padding-LR">
        <div class="col">
          <ion-label class="order-toolbar-label"
            >{{
              data?.table_label
                ? data?.table_label
                : ('order_detail_table_label' | translate)
            }}
          </ion-label>
          <ion-label class="order-toolbar-value">{{ data?.table }}</ion-label>
        </div>
        <div class="col right">
          <ion-label class="order-toolbar-label"
            >{{ 'order_detail_total_label' | translate }}
            {{ currency_symbol }}</ion-label
          >
          <ion-label class="order-toolbar-value">{{
            amount
              ? (amount.total | number : '1.2-2')
              : (data?.amount | number : '1.2-2')
          }}</ion-label>
        </div>
      </div>
    </ion-toolbar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarOrderDetailComponent {
  @Input() data: Order | Checkout | null = null;
  @Input() amount: Amount | null = null;
  @Input() currency_symbol: string | undefined = '€';
}
