import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appItemColorDark]'
})
export class ItemColorDarkDirective implements OnInit, OnChanges{

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'l4pay-list-item-bkg');
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes called');
  }

}
