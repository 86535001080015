import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amount' })
export class AmountPipe implements PipeTransform {
  transform(value: string | null, ...args: any[]) {
    value = value ? value : '';
    const sign = args[0];
    const checkout = args[1];
    return checkout ? `${sign}${value}` : value;
  }
}
