import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Amount } from 'src/app/models/amount';

@Component({
  selector: 'app-l4pay-total',
  template: `
    <ion-grid
      class="l4pay-padding-LR l4pay-margin-top amount-items amount-total ion-no-padding"
      *ngIf="!splitAmount"
    >
      <ion-row class="ion-no-padding row-vertical-center">
        <ion-col size="8" class="total-label"
          >{{ label4 | translate | uppercase }}:</ion-col
        >
        <ion-col class="total" [style.text-align]="'right'"
          >{{ currency_symbol }}
          {{ amount ? (amount?.residualFixed | number : '1.2-2') : 0 }}
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding " *ngIf="fixedPrice || tip">
        <ion-col size="6" class="fixedprice-label"
          >{{ label2 | translate }}:</ion-col
        >
        <ion-col class="amount"
          >{{ currency_symbol }}
          {{
            amount && amount.value2 > 0
              ? (amount.value2 | number : '1.2-2' | amount : '+' : checkout)
              : '0.00'
          }}</ion-col
        >
      </ion-row>
      <ion-row class="ion-padding-bottom row-vertical-center" *ngIf="label3">
        <ion-col size="8" class="total-label"
          >{{ label3 | translate }}:</ion-col
        >
        <ion-col class="total" [style.text-align]="'right'"
          >{{ currency_symbol }}
          {{ amount ? (amount?.residualAmount | number : '1.2-2') : 0 }}
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid
      class="l4pay-padding-LR l4pay-margin-top amount-items amount-total ion-no-padding"
      *ngIf="splitAmount"
    >
      <ion-row class="ion-no-padding row-vertical-center" *ngIf="splitAmount">
        <ion-col size="8" class="total-label"
          >{{ 'order_detail_subtotal_label' | translate | uppercase }}:</ion-col
        >
        <ion-col class="total" [style.text-align]="'right'"
          >{{ currency_symbol }}
          {{ amount ? (splitAmount | number : '1.2-2') : 0 }}
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding " *ngIf="fixedPrice || tip">
        <ion-col size="6" class="fixedprice-label"
          >{{ label2 | translate }}:</ion-col
        >
        <ion-col class="amount"
          >{{ currency_symbol }}
          {{
            amount && amount.value2 > 0
              ? (amount.value2 | number : '1.2-2' | amount : '+' : checkout)
              : '0.00'
          }}</ion-col
        >
      </ion-row>
      <ion-row class="ion-padding-bottom row-vertical-center" *ngIf="label3">
        <ion-col size="8" class="total-label"
          >{{ label3 | translate }}:</ion-col
        >
        <ion-col class="total" [style.text-align]="'right'"
          >{{ currency_symbol }}
          {{ splitValue2 ? (splitValue2 | number : '1.2-2') : 0 }}
        </ion-col>
      </ion-row>
    </ion-grid>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class L4PayTotalComponent implements OnChanges {
  @Input() checkout = false;
  @Input() amount: Amount | null = null;
  @Input() label1: string | null = null; // Subtotal / Discount
  @Input() label2: string | null = null; // Fixed price / TIP
  @Input() label3: string | null = null; // Total / Total amount due
  @Input() label4: string | null = null; // Residual to pay
  @Input() fixedPrice = false;
  @Input() tip = false;
  @Input() currency_symbol: string | undefined = '€';
  // tip = 0;
  // total = 0;
  splitAmount = 0;
  value2 = 0;
  splitValue2 = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.amount) {
      this.amount = changes.amount.currentValue;
      this.splitAmount = this.amount?.splitAmount ?? 0;
      this.value2 = this.amount?.value2 ?? 0;
      this.splitValue2 = this.splitAmount + this.value2;
    }
  }
}
