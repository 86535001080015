import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { fields } from 'src/app/utility/customer-type-fields';
/**
 * this Directive is used in  components: ReceiptFormComponent
 * it's a structural directive to add or remove a field of the form according the 'controlName' and the customerType selected
 * if the field is includes
 */
@Directive({
  selector: '[ifCustomerType]',
})
export class IfCustomerTypeDirective {
  private visible = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  /**
   * @param customer object {controlName: string; type: string}
   * @controlName name of the field
   * @type customer selected [Company, Micro Company, Private, Foreign]
   */
  @Input() set ifCustomerType(customer: {
    controlName: string;
    type: string | null;
  }) {
    const { controlName, type } = customer;
    const result = type ? fields[controlName].includes(type) : false;
    if (result && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.visible = true;
    } else if (!result && this.visible) {
      this.viewContainer.clear();
      this.visible = false;
    }
  }
}
