import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ERROR_CODE } from '../../../models/error-response';
import { translations } from 'src/assets/data/translations';

@Component({
  selector: 'app-l4pay-general-error',
  templateUrl: './general-error.component.html',
})
export class GeneralErrorComponent implements OnInit {
  @Input() errors: any = [];
  @Input() invalid_status = false;
  @Input() phase = '';
  @Input() code = 999;
  phaseOutput = '';
  noErrors = true;
  cashsystem_does_not_respond_msg = false;
  constructor(private modalCtrl: ModalController, private router: Router) {}

  ngOnInit() {
    if (this.errors?.length > 0) {
      if (this.errors[0].desc === 'error.cashsystem_does_not_respond') {
        this.code = this.errors[0].code;
        this.cashsystem_does_not_respond_msg = true;
      }
      this.noErrors = false;
    }
  }

  dismissModal() {
    if (this.invalid_status) {
      this.modalCtrl.dismiss();
      this.router.navigateByUrl('/');
    } else {
      this.modalCtrl.dismiss();
      switch (this.code) {
        case ERROR_CODE.WS_NOT_AVAILABLE:
          this.router.navigateByUrl('/', { replaceUrl: true });
          break;
        case ERROR_CODE.ORDER: // error = 1 -> back to home
          this.router.navigateByUrl('/', { replaceUrl: true });
          break;
        case ERROR_CODE.CHECKOUT:
          this.router.navigateByUrl('/order', {
            replaceUrl: true,
          });
          break;
        case ERROR_CODE.RECEIPT:
          this.router.navigateByUrl('/order/checkout', { replaceUrl: true });
          break;
        case ERROR_CODE.NOT_POSSIBLE_CHECKOUT:
          break;
        case ERROR_CODE.CASHSYSTEM_DOES_NOT_RESPOND:
          this.router.navigateByUrl('/', { replaceUrl: true });
          break;
        default:
          this.router.navigateByUrl('/order', {
            replaceUrl: true,
          });
          break;
      }
    }
  }
}
