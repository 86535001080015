import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-l4pay-footer',
  template: `
    <ion-footer
      [mode]="'md'"
      class="ion-no-border ion-padding"
      collapse="fade"
      [class.shadow]="shadow"
      [style.background]="'var(--4pay-primary-bkg)'"
    >
      <ion-toolbar>
        <ion-button
          *ngIf="splitVisible"
          (click)="split.emit()"
          expand="block"
          size="large"
          [fill]="fill ? fill : 'solid'"
          [color]="color ? color : 'primary'"
          [disabled]="splitDisabled"
        >
          {{ 'pay_a_part' | translate }}
        </ion-button>
        <ion-button
          *ngIf="!start"
          (click)="goTo.emit()"
          expand="block"
          size="large"
          [fill]="fill ? fill : 'solid'"
          [color]="color ? color : 'primary'"
          [disabled]="disabled"
        >
          <ng-content></ng-content>
        </ion-button>
        <div *ngIf="start" class="payment-footer">
          <ion-progress-bar mode="md" type="indeterminate"></ion-progress-bar>
        </div>
      </ion-toolbar>
    </ion-footer>
  `,
  styles: [
    `
      .shadow {
        box-shadow: 0 2px 15px #00000061;
        background: var(--4pay-logo-background);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class L4PayFooterComponent {
  @Input() color: string | null = null;
  @Input() shadow = true;
  @Input() fill: string | null = null;
  @Input() disabled: boolean | null = null;
  @Input() start = false;
  @Input() splitVisible: boolean | null = null;
  @Input() splitDisabled: boolean | null = null;
  @Output() goTo = new EventEmitter<void>();
  @Output() split = new EventEmitter<void>();
}
