/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Connection } from './../../models/connection';
import { BehaviorSubject } from 'rxjs';
import { AuthStore } from './auth.store';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export type ConnectionType = 'network' | 'ws';
@Injectable({ providedIn: 'root' })
export class GlobalStore {
  private _start = new BehaviorSubject<boolean>(false); // behaviorsubject emit when button of the footer has clicked
  private _reloadPage = new BehaviorSubject<boolean>(false);
  private _abortAllSessions = new BehaviorSubject<boolean>(false);
  public _errorConnection = new BehaviorSubject<Connection>({
    type: 'network',
    error: false,
  });
  private _networkConnection = new BehaviorSubject<boolean | null>(null);
  private _canDeactivate = new BehaviorSubject<boolean>(true);
  private _connectionStateMessage = new BehaviorSubject<Record<
    ConnectionType,
    string
  > | null>(null);
  reloadPage$ = this._reloadPage.asObservable();
  abortAllSessions$ = this._abortAllSessions.asObservable();
  errorConnection$ = this._errorConnection.asObservable();
  canDeactivate$ = this._canDeactivate.asObservable();
  connectionStateMessage$ = this._connectionStateMessage.asObservable();
  networkConnection$ = this._networkConnection.asObservable();
  start$ = this._start.asObservable();

  constructor(private authStore: AuthStore) {}

  /**
   * clean all storage except invoice storage
   */
  cleanAll(): void {
    const initialURL = `${environment.l4pay}/auth?restaurantUUID=${this.authStore.restaurantUUID}&tableUUID=${this.authStore.tableUUID}`;
    window.location.href = initialURL;
  }

  dispatchReloadPage(reload: boolean): void {
    console.log('reload', reload);
    this._reloadPage.next(reload);
  }

  dispatchAbortAllSessions(abort: boolean): void {
    console.log('abortAllSessions', abort);
    this._abortAllSessions.next(abort);
  }

  dispatchErrorConnection(connectionErrorType: Connection): void {
    this._errorConnection.next(connectionErrorType);
  }

  dispatchNetworkConnectionChanges(changes: boolean): void {
    this._networkConnection.next(changes);
  }

  dispatchCanDeactivateGuard(can: boolean): void {
    this._canDeactivate.next(can);
  }

  dispatchConnectionStateMessage(msg: Record<ConnectionType, string>): void {
    this._connectionStateMessage.next(msg);
  }

  dispatchStartAction(start: boolean): void {
    this._start.next(start);
  }

  dispatchWsInitialized(): void {
    localStorage.setItem('ws', 'started');
  }

  get wsInitialized() {
    return localStorage.getItem('ws');
  }
}
