export interface Config {
  payment_gateway: string;
  stripe_key: string;
  tip_section_enabled: boolean;
  fast_pay_enabled: boolean;
  restourant_id_4order: string;
  view_menu_url: string;
  template_style: TemplateStyle;
  template_style_id: string;
  splitting?: Splitting;
  digital_wallet: boolean;
  pay_with_digital_wallet: boolean;
  currency: string;
}

export interface Splitting {
  split_analytic: number;
  split_by_amount: number;
  split_by_share: number;
  split_min_amount: number;
  split_max_share_count: number;
  split_min_share_amount: number;
}

export interface ConfigStyles {
  // PRE LOAD PAGE
  l4pay_preloading_bkg: string;
  l4pay_menu_order: string;
  l4pay_labware_logo: string;
  l4pay_logo: string;
  // HOME
  restaurant_logo: string;
  order_pay: string;
  order_new: string;
  menu_icon: string;
  footer_banner: string;
  order_modal: string;
  //CHECKOUT
  tip: string;
  edit_tip: string;
  //RECEIPT
  email: string;
  email_active: string;
  hide_items: string;
  invoice: string;
  invoice_active: string;
  //PAYMENT
  payment_card: string;
  payment_payxpert: string;
  //PAYMENT FEEDBACK
  receipt: string;
  receiptDOC: string;
  //SURVEY
  good: string;
  medium: string;
  bad: string;
  good_checked: string;
  medium_checked: string;
  bad_checked: string;
  //INFO-MODAL
  person_pay: string;
  code_punto_cassa: string;
  pagamento_sicuro: string;
  scontrino: string;
  //STYLES
  styles: Style;
}

export interface Style {
  [index: string]: string;
}

export class StripeConfigFR {
  name = 'FR - France';
  code = 'FR';
  currency = 'eur';
}

export class StripeConfigIT {
  name = 'IT - Italia';
  code = 'IT';
  currency = 'eur';
}

export interface TemplateStyle {
  color_primary: string;
  color_primary_contrast: string;
  color_primary_contrast_rgb: string;
  color_primary_rgb: string;
  color_primary_shade: string;
  color_primary_tint: string;
  color_secondary: string;
  color_secondary_rgb: string;
  description: string;
  id: number;
  image_url: string;
}
