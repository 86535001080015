import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { SubSink } from 'subsink';
import { L4PayToggleComponent } from './l4pay-toggle.component';

@Component({
  selector: 'app-l4pay-toggle-group',
  template: `
    <div
      [ngClass]="{
        cards: checkout,
        amountSuggested: amountSuggested,
        'survey-buttons': survey,
        payment: payment
      }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .cards,
      .survey-buttons {
        display: flex;
        flex: 0 1 100%;
        flex-wrap: wrap;
      }

      .amountSuggested {
        display: flex;
        flex: 0 1 100%;
        flex-wrap: wrap;
      }

      .payment {
        display: grid;
        gap: 1rem;
        margin: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
      }

      .survey-buttons {
        padding-right: 0;
        grid-template-columns: repeat(3, 1fr);
      }
    `,
  ],
})
export class L4PayToggleGroupComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() amountSuggested = false;
  @Input() survey = false;
  @Input() checkout = false;
  @Input() payment = false;
  @Input() label: string | null = null;
  @Output() selected = new EventEmitter<any>();
  @ContentChildren(L4PayToggleComponent)
  groups!: QueryList<L4PayToggleComponent>;
  subs = new SubSink();

  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * Invoked when all children (groups) are ready
   */

  ngAfterViewInit() {
    this.processChildren(); // first call to processChildren
    this.subs.add(this.groups.changes.subscribe((_) => this.processChildren()));
  }

  processChildren() {
    this.groups?.toArray().forEach((card) => {
      // subscribe on output toggle
      this.subs.add(
        card.toggle.subscribe(() => {
          card.opened = !card.opened;
          let toggleData: any = null;
          if (card.opened) {
            // tip = +card.card.nativeElement.innerText.split('%')[0];
            toggleData = card.data;
          }
          this.selected.emit(toggleData);
          this.resetCardGroup(card);
        })
      );
      // focus the card if it matches with the label from Input
      if (card.data.label === this.label) {
        card.opened = true;
      }
    });
  }

  // reset card style
  resetCardGroup(card: L4PayToggleComponent): void {
    this.groups.toArray().map((c) => (card !== c ? (c.opened = false) : c));
  }

  // deselect all card
  deselectAll(): void {
    this.groups.toArray().map((card) => (card.opened = false));
  }

  unsubscribe(): void {
    this.subs.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
