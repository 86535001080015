import { ConnectionErrorModalComponent } from './../components/connection-error-modal.component';
import { distinctUntilChanged, startWith, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { filter, of, Subscription, takeUntil, timer, combineLatest } from 'rxjs';
import { GlobalStore } from './../../core/stores/global.store';
import { ComponentRef, Directive, Input, OnDestroy, OnInit, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';

export interface ConnectionErrorComponentItem {
  component: Type<any>;
  error: boolean;
}

@Directive({
  selector: '[noConnection]'
})
export class NoConnectionDirective implements OnInit, OnDestroy {
  connection = false;
  visible = false;
  subs = new SubSink();
  constructor(
    private globalStore: GlobalStore,
    private router: Router,
    private route: ActivatedRoute,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  // @Input() set noConnection(connection: boolean) {
  //   this.connection = connection;
  // }

  ngOnInit(): void {
    // console.log('NoConnectionDirective - initialize');
    this.subs.add(this.globalStore.networkConnection$
      .pipe(
        filter(v => v !== null),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if (!val) {// network offline
          this.globalStore.dispatchCanDeactivateGuard(false);
          this.globalStore.dispatchReloadPage(false);
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else { // network online
          this.viewContainer.clear();
          this.globalStore.dispatchCanDeactivateGuard(true);
          this.globalStore.dispatchReloadPage(true);
        }

      })
    );

    this.subs.add(
      this.globalStore.errorConnection$
      .pipe(
        withLatestFrom(this.globalStore.networkConnection$),
        switchMap(([connection, network]) => {
          // console.log('ws state:', connection);
          // console.log('network state:', network ? 'online' : 'offline');
          if (network && connection.type === 'ws' && connection.error && !this.visible) {
            this.globalStore.dispatchCanDeactivateGuard(false);
            this.viewContainer.createEmbeddedView(this.templateRef); // create alert
            this.visible = true;
            return timer(env.wsTimeout).pipe(
                    takeUntil(this.globalStore.errorConnection$.pipe(filter(val => !val.error)))
                  );
          } else {
            return of(false);
          }
        })
      )
      .subscribe(val => {
        if (val === 0 && this.visible) { // timeout is over, only for ws connection
          // close the modal and back to HomePage
          this.viewContainer.clear();
          this.visible = false;
          this.globalStore.dispatchCanDeactivateGuard(true);
          this.reload();
        } else if(!val && this.visible) {
          this.viewContainer.clear();
          this.visible = false;
          this.globalStore.dispatchCanDeactivateGuard(true); // guard disabled
          this.globalStore.dispatchReloadPage(true);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * reload the application removing all data from localstorage except invoice
   */
  reload() {
    this.globalStore.cleanAll();
  }

}
