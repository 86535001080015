import { SurveyGuard } from './core/guards/survey.guard';
import { ErrorPage } from './views/error/error.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

export type RouteURL = 'home' | 'order' | 'menu' | 'new';

export const routes: Routes = [
  {
    path: 'home',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./views/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./views/menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'order',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./views/order/order.module').then((m) => m.OrderPageModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'survey',
    canLoad: [SurveyGuard],
    loadChildren: () =>
      import('./views/survey/survey.module').then((m) => m.SurveyPageModule),
  },
  {
    path: 'error',
    canActivate: [AuthGuard],
    component: ErrorPage,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
