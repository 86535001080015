/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth } from 'src/app/models/auth';
import { HttpHandlerErrorService } from './../services/http-handler-error.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  private _loading = new BehaviorSubject<boolean>(false);
  loading$ = this._loading.asObservable();

  constructor(private errorHandlerService: HttpHandlerErrorService) {}

  dispatchLoader(loader: boolean): void {
    this._loading.next(loader);
  }

  dispatchViewErrorMessage(error: string): void {
    this.errorHandlerService.errorToast(error);
  }

  getAuth(): Auth {
    const authStorage = localStorage.getItem('auth');
    return authStorage ? JSON.parse(authStorage) : null;
  }

  dispatchUpdateAuthInformation(authInfo: Auth) {
    localStorage.setItem('auth', JSON.stringify(authInfo));
  }

  dispatchRefCode(refCode: string) {
    const currentAuth = this.getAuth();
    const updatedTableUUIDDecoded = {
      ...this.tableUUIDDecoded,
      ref_code: refCode,
    };
    const tableUUID = JSON.stringify(updatedTableUUIDDecoded);
    const encodedData = btoa(tableUUID);
    currentAuth.tableUUID = encodedData;
    this.dispatchUpdateAuthInformation(currentAuth);
  }

  get restaurantUUID() {
    return this.getAuth().restaurantUUID;
  }

  get tableUUID() {
    return this.getAuth().tableUUID;
  }

  get tableUUIDDecoded() {
    const tableUUID = atob(this.getAuth().tableUUID) ?? '';
    return JSON.parse(tableUUID);
  }

  get stripeKey() {
    return this.getAuth().stripeKey;
  }
}
