import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ConfigStore } from 'src/app/core/stores/config.store';
import { Style } from 'src/app/models/config';

@Directive({
  selector: '[cssCustom]'
})
export class CssCustomDirective implements OnInit, OnDestroy{
  name!: string;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private configStore: ConfigStore
  ) {}
  @Input() set cssCustom(name: string) {
    this.name = name;
  }
  ngOnInit(): void {
    // console.log('csscutom init..', this.elementRef);
    const styles: Style = this.configStore.config.styles;
    if (this.name && styles[this.name]) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', styles[this.name]);
    }
  }

  ngOnDestroy(): void {
    console.log('csscutom destroy..');
  }
}
