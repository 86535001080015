import { WebSocketService } from './../services/websocket.service';
import { OrderStore } from 'src/app/core/stores/order.store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, take, tap } from 'rxjs/operators';
import { Auth } from 'src/app/models/auth';
import { AuthStore } from '../stores/auth.store';
import { GuestStore } from '../stores/guest.store';

@Injectable({ providedIn: 'root' })
export class SurveyGuard implements CanActivate, CanLoad {
  constructor(
    private authStore: AuthStore,
    private guestStore: GuestStore,
    private router: Router
  ) {}

  canLoad(): boolean | Observable<boolean> {
    return this.canActivate();
  }

  canActivate(): Observable<boolean>{
    return this.guestStore.guest$.pipe(
      take(1),
      map(guest => {
        if (guest && this.getPayment()) {
          console.log('can activate');
          return true;
        } else {
          // get restaurantUUID and tableUUID from localstorage if they have been saved
          this.router.navigateByUrl(`/`);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigateByUrl(`/`);
        return of(false);
      })
    );
  }

  getPayment(): boolean {
    const paymentStore = localStorage.getItem('payment');
    return paymentStore ? true : false;
  }

}
