import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ReceiptStore } from 'src/app/core/stores/receipt.store';
import { Directive, OnInit, OnDestroy, Input, ElementRef, Renderer2 } from '@angular/core';
@Directive({
  selector: '[l4payInvoiceErrorField]'
})
export class PaymentInvoiceErrorFieldDirective implements OnInit, OnDestroy{
  sub: Subscription | null = null;
  field: string | null = null;

  constructor(
    private receiptStore: ReceiptStore,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @Input() set l4payInvoiceErrorField(field: string) {
    this.field = field;
  }

  ngOnInit(): void {
    this.sub = this.receiptStore.invoice_04_fields$
      .pipe(filter(Boolean))
      .subscribe(errors => {
        errors.map(invoiceErrorItem => {
          if (invoiceErrorItem.error.field === this.field) {
            this.renderer.addClass(this.el.nativeElement, 'l4pay-input-error');
          }
          return invoiceErrorItem;
        });
      });
  }

  ngOnDestroy(): void {
    // console.log('invoice error field directive destroyed');
    this.sub?.unsubscribe();
  }
}
