/* eslint-disable no-underscore-dangle */
import { PopupoverStatus } from './../../models/invoice';
import { Amount } from './../../models/amount';
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Order } from 'src/app/models/order';
import { filter, map } from 'rxjs/operators';
import { AuthStore } from './auth.store';

export interface SplitPayment {
  splitMode: string;
  splitStatus: boolean;
  splitAmount: number;
  total_parts: number;
  parts_to_pay: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrderStore {
  private skeletons = Array.from({ length: 5 }).map(() => null);
  public _order = new BehaviorSubject<Order | null>(null);
  private _channel = new BehaviorSubject<boolean>(false);
  private customerPopover = new BehaviorSubject<PopupoverStatus>('close');
  private _splitPaymentStatus = new BehaviorSubject<boolean>(
    this.getSplitPaymentStatus()
  );
  private _splitPaymentMode = new BehaviorSubject(this.getSplitPaymentMode());
  order$ = this._order.asObservable();
  channel$ = this._channel.asObservable();
  customerPopover$ = this.customerPopover.asObservable();
  splitPaymentStatus$ = this._splitPaymentStatus.asObservable();
  splitPaymentMode$ = this._splitPaymentMode.asObservable();

  orderAmount$ = this.order$.pipe(
    map((order: Order | null) => {
      const amount: Amount = {
        total: order ? order.amount / 100 : 0,
        totalDiscount: order ? order.amount_discount / 100 : 0,
        residualAmount: order?.amount_residual
          ? order.amount_residual / 100
          : 0,
        residualFixed: order?.amount_residual ? order.amount_residual / 100 : 0,
        value1: order ? order.amount_subtotal / 100 : 0,
        value2: 0, // order ? order.amount_fixed / 100 : 0
        splitAmount: order ? order.split_amount / 100 : 0,
      };
      return amount;
    })
  );

  constructor(private authStore: AuthStore) {}

  getSkeletons(): Array<null> {
    return [...this.skeletons];
  }

  selectCurrentOrder(): Order | null {
    return this._order.getValue();
  }

  getSplitPayment() {
    const data = localStorage.getItem('splitPayment') ?? null;
    if (data) {
      const parsedJson: SplitPayment = JSON.parse(data);
      return parsedJson;
    }
    return null;
  }

  getSplitPaymentStatus() {
    let status = false;
    const data = localStorage.getItem('splitPayment');
    if (data) {
      const parsedJson: SplitPayment = JSON.parse(data);
      status = parsedJson.splitStatus;
    }
    return status;
  }

  getSplitPaymentMode() {
    let mode = '';
    const data = localStorage.getItem('splitPayment');
    if (data) {
      const parsedJson: SplitPayment = JSON.parse(data);
      mode = parsedJson.splitMode;
    }
    return mode;
  }

  dispatchInitOrder(): void {
    const storage = localStorage.getItem('order');
    if (storage) {
      // initial order from localstorage
      const order: Order = JSON.parse(storage);
      this.dispatchSetOrder(order);
    }
  }

  dispatchSetOrder(order: Order): void {
    // const prod: OrderItem[] = order.products.map((p, index) => index === 0 ? {...p, quantity: p.quantity + 700 } : p);
    // this._order.next({...order, products: [ ...prod ]});
    this._order.next(order); // emit new order got from websocket
    localStorage.setItem('order', JSON.stringify(order)); // save the order in localstorage
  }

  resetOrder(): void {
    // reset order store
    this._order.next(null);
    // reset order in local storage
    localStorage.removeItem('order');
    // reset checkout in local storage
    localStorage.removeItem('checkout');
    localStorage.removeItem('payxpertStatus');
  }

  dispatchIsOpenPopover(status: PopupoverStatus): void {
    this.customerPopover.next(status);
  }

  dispatchSubscribeChannel(sub: boolean): void {
    this._channel.next(sub);
  }

  dispatchEmptyOrder(): void {
    const orderEmpty: Partial<Order> = { products: [] };
    this.dispatchSetOrder(orderEmpty as Order);
  }

  dispatchSetSplitPaymentStatus(status: boolean): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ ...parsedSplitPayment, splitStatus: status })
      );
    } else {
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ splitStatus: status })
      );
    }

    this._splitPaymentStatus.next(status);
  }

  dispatchSetSplitPaymentMode(mode: string): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ ...parsedSplitPayment, splitMode: mode })
      );
    } else {
      localStorage.setItem('splitPayment', JSON.stringify({ splitMode: mode }));
    }
    this._splitPaymentMode.next(mode);
  }

  dispatchResetSplitPaymentMode(): void {
    this._splitPaymentMode.next('');
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      delete parsedSplitPayment.splitMode;
      localStorage.setItem('splitPayment', JSON.stringify(parsedSplitPayment));
    }
  }

  dispatchResetSplitPayment(): void {
    localStorage.removeItem('splitPayment');
  }

  reset(): void {
    this._order.next(null); // emit null
    this._channel.next(false);
  }

  clean(): void {
    this.reset();
    localStorage.removeItem('order');
  }
}
