import { translations } from 'src/assets/data/translations';
import { AuthStore } from './../stores/auth.store';
import { GuestStore } from './../stores/guest.store';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { LanguageService } from '../services/language.service';

/**
 * Back to Home when we receive a 401.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private guestStore: GuestStore,
    private authStore: AuthStore,
    private router: Router,
    private lang: LanguageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | Observable<any> {
    // clone request and replace 'http://' with 'https://' at the same time
    const secureReq = req.clone({
      url: req.url.replace('http://', env.production ? 'https://' : 'http://'),
    });
    return next.handle(secureReq).pipe(
      catchError((errorRes) => {
        let errorsActive: boolean | null = null;
        const is4LoyaltyError = errorRes?.error?.error_code ? true : false;
        if (
          errorRes instanceof HttpErrorResponse &&
          errorRes.status === 401 &&
          !is4LoyaltyError
        ) {
          console.log('auth interceptor error');
          this.guestStore.removeGuest();
          this.router.navigate(['/auth'], {
            queryParams: {
              restaurantUUID: this.authStore.restaurantUUID,
              tableUUID: this.authStore.tableUUID,
            },
          });
        } else {
          errorsActive =
            !!errorRes?.error?.response?.errors?.length || is4LoyaltyError
              ? true
              : false;
        }
        return errorsActive
          ? throwError(() => errorRes)
          : throwError(
              () =>
                new Error(
                  translations[this.lang.language].service_not_available
                )
            );
      })
    );
  }
}
