import { WebSocketService } from './../services/websocket.service';
import { OrderStore } from 'src/app/core/stores/order.store';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, take, tap } from 'rxjs/operators';
import { Auth } from 'src/app/models/auth';
import { AuthStore } from '../stores/auth.store';
import { GuestStore } from '../stores/guest.store';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authStore: AuthStore,
    private orderStore: OrderStore,
    private guestStore: GuestStore,
    private ws: WebSocketService,
    private router: Router
  ) {}

  canLoad(): boolean | Observable<boolean> {
    return this.canActivate();
  }

  canActivate(): Observable<boolean>{
    return this.guestStore.guest$.pipe(
      take(1),
      map(guest => {
        const storage = localStorage.getItem('guest');
        if (guest && storage) {
          console.log('can activate');
          return true;
        } else {
          // get restaurantUUID and tableUUID from localstorage if they have been saved
          this.router.navigateByUrl(`/auth${this.getAuthCredential()}`);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigateByUrl(`/auth${this.getAuthCredential()}`);
        return of(false);
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  getAuthCredential(): string {
    const authStorage = localStorage.getItem('auth');
    let authURL = '';
    if (authStorage) {
      const auth: Auth = JSON.parse(authStorage);
      authURL = `?restaurantUUID=${auth.restaurantUUID}&tableUUID=${auth.tableUUID}`;
    }

    return authURL;
  }

}
