import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'countryFilter'})
export class CountryPipe implements PipeTransform {
  transform(country: Array<string>, value: string | null) {
    if (country && value) {
      // remove space
      return country.filter(name => this.noSpace(name).toLowerCase().includes(this.noSpace(value)?.toLowerCase()));
    }
    return country;
  }

  noSpace(value: string): string {
    return value.replace (/(\s|-|\.)+/g, '');
  }
}
