import { environment } from 'src/environments/environment';
import { translations } from 'src/assets/data/translations';
export interface ErrorResponse {
  invalidate_status: boolean;
  errors: Error[];
}

export enum ERROR_CODE {
  ORDER = 1,
  CHECKOUT = 2,
  RECEIPT = 3,
  NOT_POSSIBLE_CHECKOUT = 8,
  START_PAYMENT = 123,
  WS_NOT_AVAILABLE = 0,
  CASHSYSTEM_DOES_NOT_RESPOND = 500,
}

export interface Error {
  code: ERROR_CODE;
  desc: string;
  field?: string;
}

export const serviceNotAvailable = (): Error => ({
  code: 0,
  desc: translations[environment.language][environment.serviceNotAvailable],
});
