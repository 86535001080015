export const environment = {
  production: true,
  host: 'https://4pay.labwaretest.cloud/be/', //BE
  l4pay: 'https://4pay.labwaretest.cloud', //FE
  fidelityUrl: 'https://4loyalty.labwaretest.cloud/', // 4Fidelity
  wshost: '4pay-ws.labwaretest.cloud', // Websocket host name
  wsPort: 6001,
  wsEncrypted: true,
  wsTimeout: 180000, // milliseconds 1000 * 180 = 3 min
  pusherAppKey: '4pay-app-key',
  pusherAppCluster: 'mt1',
  appId: '4pay', //4pay
  language: 'en', // available en | fr. you can add a new language directly inside the file 'src/assets/data/translations.ts'
  version: '1.0',
  FranceVATCodeLabel: 'invoice_form_france_vat_code', // translation key. you can find the value in 'src/assets/data/translations.ts'
  AbroadVATCodeLabel: 'invoice_form_abroad_vat_code', // translation key. you can find the value in 'src/assets/data/translations.ts'
  serviceNotAvailable: 'Service not available: please try again later.',
  partsToPay: 2, // numero di parti che sta pagando  -> richiesto solo in caso di paymentProofRequested=true
  paymentMode: 0, // 0 = pagamento completo , 1 = pagamento per importo , 2 = pagamento Analitico
  keysToLeft: ['invoice'], // keys of localstorage will be not remove before signin
  cashSystemNoResponseMessage:
    'The payment was successful. Go to the till to collect your receipt.',
  link4Order: 'https://dev.4order.it/app/',
};
