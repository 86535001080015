/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { Toggle } from './l4pay-toggle.component';

@Directive({
  selector: '[toggleType]'
})
export class ToggleTypeDirective implements OnChanges{
  private _checked = false;
  private _previewChecked = false;
  private _survey = false;
  private _type: Toggle | null = null;
  private _data: any | null = null;

  @Input() set data(data: any) {
    this._data = data;
  }

  @Input() set type(type: Toggle) {
    this._type = type;
    if (type === 'tip') {
      this.renderer.addClass(this.el.nativeElement, 'card');
    }
  }

  @Input() set checked(checked: boolean) {
    this._checked = checked;
    // console.log('input checked', this._checked);
  }

  @Input() set survey(survey: boolean) {
    this._survey = survey;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.type) {
      this._type = changes.type.currentValue;
      // console.log('first changes', this._type);
      if(changes.type.firstChange && this._type === 'survey') {
        const preference = changes.data.currentValue;
        this.setPreference(preference);
      }
    }

    if(changes.checked && this._type === 'survey') {
      if (changes.checked.firstChange) {
        this._previewChecked = changes.checked.currentValue;
      } else {
        this._previewChecked = changes.checked.previousValue;
        this.renderer.removeClass(this.el.nativeElement, this._previewChecked ? `${this._data}-checked` : this._data);
      }
      // console.log(this._previewChecked, this._checked, this._type, this._data);
      this.renderer.addClass(this.el.nativeElement, this._checked ? `${this._data}-checked` : this._data);
    }
  }

  setPreference(preference: string): void {
    this.renderer.addClass(this.el.nativeElement, preference);
  }
}
