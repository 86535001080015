import { WebSocketService } from './../services/websocket.service';
import { switchMap } from 'rxjs/operators';
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Checkout } from 'src/app/models/checkout';
import { Tip, TipType } from 'src/app/models/tip';
import { Amount } from 'src/app/models/amount';
import { toNumber } from 'src/app/utility/convert';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { clone } from 'src/app/utility/clone-object';
import { OrderItem } from 'src/app/models/order-item';

// { amount: 0, amount_tip: 0, amount_with_tip: 0, discount: 0, label: '' }

@Injectable({ providedIn: 'root' })
export class CheckoutStore {
  customTipSelected = 0;
  private skeletons = Array.from({ length: 3 }).map(() => null);
  private _loadSkeleton = new BehaviorSubject<boolean>(false);
  private checkout = new BehaviorSubject<Checkout | null>(null);
  private startPaymentCheckout = new BehaviorSubject<Checkout | null>(null);
  private tipSelected = new BehaviorSubject<Tip | null>(null);
  private _tipManualSelected = new BehaviorSubject<Tip | null>(null);
  public typeTipSelected = new BehaviorSubject<TipType | null>(null); // default manual tip
  private _discount = new BehaviorSubject<number>(0);
  private _tip = new BehaviorSubject<Tip | null>(null); // could be percent/manual
  private _amount = new BehaviorSubject<number>(0);
  private _residualAmount = new BehaviorSubject<number>(0);
  private _channel = new BehaviorSubject<boolean>(false);
  private _tips = new BehaviorSubject<Tip[]>([]);
  private _splitPaymentAmount = new BehaviorSubject<number>(
    this.getSplitPaymentAmount()
  );
  private _analyticProducts = new BehaviorSubject<OrderItem[] | null>(
    this.getAnalyticProducts()
  );

  channel$ = this._channel.asObservable();
  checkout$ = this.checkout.asObservable();
  startPaymentCheckout$ = this.startPaymentCheckout.asObservable();
  loadSkeleton$ = this._loadSkeleton.asObservable();
  tipManualSelected$ = this._tipManualSelected.asObservable();
  // tipManualSelected$ = this._tipManualSelected.asObservable().pipe(map(tip => tip ? {...tip, amount: tip.amount / 100 } : null));
  typeTip$ = this.typeTipSelected.asObservable().pipe(distinctUntilChanged());
  discount$ = this._discount.asObservable();
  _tip$ = this._tip.asObservable();
  amount$ = this._amount.asObservable();
  residualAmount$ = this._residualAmount.asObservable();
  tips$ = this._tips.asObservable();
  splitPaymentAmount$ = this._splitPaymentAmount.asObservable();
  analyticProducts$ = this._analyticProducts.asObservable();
  // total$ is a derived state calculated between amount$, discount$, _tip$ and typeTip$ inserted
  total$: Observable<Amount> = combineLatest([
    this.amount$,
    this.discount$,
    this._tip$,
    this.residualAmount$,
    this.splitPaymentAmount$,
  ]).pipe(
    switchMap(([amount, discount, tip, residual, splitAmount]) => {
      const tip_amount = tip ? tip.amount : 0;
      discount = !discount ? 0 : discount;
      return of({
        total: (amount - discount + tip_amount) / 100,
        totalDiscount: 0,
        value1: discount / 100,
        value2: tip_amount / 100,
        residualAmount: (residual - discount + tip_amount) / 100,
        residualFixed: (residual - discount) / 100,
        splitAmount: splitAmount / 100,
      });
    })
  );

  getSkeletons(): Array<null> {
    return [...this.skeletons];
  }

  getSplitPaymentAmount() {
    const curSplitPayment = localStorage.getItem('splitPayment') ?? null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      return parsedSplitPayment.splitAmount ?? 0;
    }
    return 0;
  }

  getAnalyticProducts() {
    const analyticProducts = localStorage.getItem('analyticProducts') ?? null;
    if (analyticProducts) {
      return JSON.parse(analyticProducts);
    }
    return null;
  }

  /**
   * init checkout data.
   * the method called by {@link WebSocketService} after we received the order with tips from cash system
   * @param checkout Order with Tips
   */
  dispatchSetCheckout(checkout: Checkout): void {
    // convert all amount value from 'string' to 'number'
    let checkoutFormatted: Checkout = {
      ...checkout,
      amount: toNumber(checkout.amount),
      amount_discount: toNumber(checkout.amount_discount),
      amount_subtotal: toNumber(checkout.amount_subtotal),
      amount_residual: toNumber(checkout.amount_residual),
    };
    const storage = localStorage.getItem('checkout');
    if (storage) {
      checkoutFormatted = { ...checkoutFormatted, ...JSON.parse(storage) };
    }
    // save into store
    this.checkout.next(checkoutFormatted);
    this._amount.next(checkoutFormatted.amount); // emit amount
    this._discount.next(checkoutFormatted.discount); // emit discount
    this._residualAmount.next(checkoutFormatted.amount_residual); // emit residual
    const tips = this._tips.getValue() || [];
    checkoutFormatted.tips = tips;
    const tip =
      tips?.find((t) => t.label === checkoutFormatted?.tip_label_selected) ||
      null;
    this.tipSelected.next(tip || null);
    this.typeTipSelected.next(checkoutFormatted.type_tip || null);
    // init custom tip amount if it exist
    this.customTipSelected =
      checkoutFormatted.type_tip === null
        ? checkoutFormatted.tip_amount || 0
        : 0;
    // update the local storage
    localStorage.setItem('checkout', JSON.stringify(checkoutFormatted));
  }

  /**
   *
   * @param tip customized and confirmed from {@link CheckoutPage}
   *
   */

  dispatchSetTipSuggested(tip: Tip | null): void {
    this._tip.next(tip);
  }

  dispatchSetTipManualSuggested(tip: Tip | null): void {
    this._tipManualSelected.next(tip);
  }

  dispatchCustomTip(curValue: number): void {
    const checkout = this.checkout.getValue();
    curValue = isNaN(curValue) ? 0 : +curValue.toFixed(2); // isNaN(value) appears when the first value of input starts with '.' / ','
    const tip: Tip = {
      amount: curValue,
      label: '',
      type: 'fisso',
      value: curValue,
    };
    // console.log('custom tip', tip);
    this._tip.next(tip);
    // this.tipSelected.next(tip);
  }

  dispatchSaveCurrentCheckoutStatus(): void {
    const curr = this.checkout.getValue();
    const tipSelected = this._tip.getValue();

    if (curr) {
      const checkout: Checkout = {
        ...curr,
        tip_amount: tipSelected ? tipSelected.amount : curr?.tip_amount,
        tip_label_selected: tipSelected ? tipSelected.label : '',
        type_tip: this.typeTipSelected.getValue(),
      };
      localStorage.setItem('checkout', JSON.stringify(checkout));
      this.customTipSelected =
        checkout.type_tip === null ? checkout.tip_amount || 0 : 0;
      this.checkout.next(checkout);
    }
  }

  dispatchSetTypeOfTip(type: TipType): void {
    this.typeTipSelected.next(type);
  }

  dispatchResetTipSelect(): void {
    this.tipSelected.next(null);
  }

  dispatchSetSplitAmount(value: number): void {
    this._splitPaymentAmount.next(value);
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ ...parsedSplitPayment, splitAmount: value })
      );
    } else {
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ splitAmount: value })
      );
    }
  }

  dispatchSetPartsToPay(value: number): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ ...parsedSplitPayment, parts_to_pay: value })
      );
    } else {
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ parts_to_pay: value })
      );
    }
  }

  dispatchResetPartsToPay(): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      delete parsedSplitPayment.parts_to_pay;
      localStorage.setItem('splitPayment', JSON.stringify(parsedSplitPayment));
    }
  }

  dispatchResetSplitAmount(): void {
    this._splitPaymentAmount.next(0);
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      delete parsedSplitPayment.splitAmount;
      localStorage.setItem('splitPayment', JSON.stringify(parsedSplitPayment));
    }
  }

  dispatchSetTotalParts(value: number): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ ...parsedSplitPayment, total_parts: value })
      );
    } else {
      localStorage.setItem(
        'splitPayment',
        JSON.stringify({ total_parts: value })
      );
    }
  }

  dispatchResetTotalParts(): void {
    const curSplitPayment = localStorage.getItem('splitPayment') || null;
    if (curSplitPayment) {
      const parsedSplitPayment = JSON.parse(curSplitPayment);
      delete parsedSplitPayment.total_parts;
      localStorage.setItem('splitPayment', JSON.stringify(parsedSplitPayment));
    }
  }

  dispatchEmptyCheckout(): void {
    const checkoutNoTips: Partial<Checkout> = { tips: [] };
    this.dispatchSetCheckout(checkoutNoTips as Checkout);
  }

  dispatchTips(curTips: Tip[]): void {
    this._tips.next(curTips);
    const checkout = this.checkout.getValue();
    if (checkout) {
      this.dispatchSetCheckout(checkout);
    }
  }

  dispatchSetStartPaymentCheckoutForFastPay(): void {
    const checkout = this.checkout.getValue();
    if (checkout) {
      this.dispatchSetStartPaymentCheckout(clone(checkout));
    }
  }

  dispatchSetStartPaymentCheckout(checkout: Checkout): void {
    localStorage.setItem('startPaymentCheckout', JSON.stringify(checkout));
    this.startPaymentCheckout.next(checkout);
  }

  dispatchSubscribeChannel(sub: boolean): void {
    this._channel.next(sub);
  }

  dispatchAnalyticProduts(products: OrderItem[]) {
    localStorage.setItem('analyticProducts', JSON.stringify(products));
    this._analyticProducts.next(products);
  }

  dispatchResetAnalyticProduts(): void {
    this._analyticProducts.next(null);
    localStorage.removeItem('analyticProducts');
  }

  dispatchResetSplitPayment(): void {
    localStorage.removeItem('splitPayment');
  }

  /**
   * reset the checkout store and the localstorage
   */
  reset(): void {
    this.customTipSelected = 0;
    this._tip.next(null);
    this._discount.next(0);
    this._amount.next(0);
    this.typeTipSelected.next(null);
    this.checkout.next(null);
    this.startPaymentCheckout.next(null);
    this._channel.next(false);
    this._splitPaymentAmount.next(0);
    localStorage.removeItem('checkout');
  }

  clean(): void {
    this.reset();
  }
}
