import { translations } from 'src/assets/data/translations';
import { environment } from 'src/environments/environment';
import { Guest } from './../../models/guest';
import { GuestStore } from './../stores/guest.store';
import { ConnectionErrorModalComponent } from './../../shared/components/connection-error-modal.component';
import { GeneralErrorComponent } from 'src/app/shared/components/general-error/general-error.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ToastController,
  ModalController,
  AlertController,
} from '@ionic/angular';
import { of, throwError, Observable, filter, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { PaymentStore } from '../stores/payment.store';
import { OrderStore } from '../stores/order.store';
import { CheckoutStore } from '../stores/checkout.store';

@Injectable({ providedIn: 'root' })
export class HttpHandlerErrorService {
  message = '';
  connectionModal: any | null = null;
  constructor(
    private toastController: ToastController,
    private alertCtl: AlertController,
    private modalCtrl: ModalController,
    private router: Router,
    private paymentStore: PaymentStore,
    private guestStore: GuestStore,
    // private orderStore: OrderStore,
    private checkoutStore: CheckoutStore
  ) {}

  async errorToast(error: string = 'Service no available') {
    const toast = await this.toastController.create({
      color: 'danger',
      message: error,
      icon: 'alert-outline',
      duration: 3000,
    });
    toast.present();
  }

  async infoToast(error: string) {
    const toast = await this.toastController.create({
      color: 'primary',
      message: error,
      icon: 'information-circle',
      duration: 3000,
    });
    toast.present();
  }

  async wsErrorToast(message: string, dismiss: boolean = false) {
    const toast = await this.toastController.create({
      color: 'danger',
      message,
      icon: 'information-circle',
    });
    if (!dismiss) {
      toast.present();
    } else {
      toast.dismiss();
    }
  }

  async wsConnectionErrorModal(message: string) {
    this.connectionModal = await this.modalCtrl.create({
      component: ConnectionErrorModalComponent,
      componentProps: {
        message,
      },
      mode: 'md',
      backdropDismiss: false,
    });
  }

  async openConnectionErrorModal() {
    await this.connectionModal.present();
  }

  handleError(error: any): Observable<any> {
    try {
      console.log('http error', error);
      const status = error.status;
      const errorResponse = error.error.response;
      switch (status) {
        case 504: // gateway timeout
          this.errorPopup(
            errorResponse.errors || [],
            this.checkInvalidateStatus(errorResponse),
            '',
            errorResponse.code
          );
          return of(null);
          break;
        case 500:
          this.errorPopup(
            errorResponse.errors || [],
            this.checkInvalidateStatus(errorResponse),
            '',
            errorResponse.code
          );
          return of(errorResponse);
          break;
        case 400:
          this.errorPopup(
            errorResponse.errors || [],
            this.checkInvalidateStatus(errorResponse),
            '',
            errorResponse.code
          );
          return of(null);
          break;
        case 403:
          this.errorPopup(
            errorResponse.errors || [],
            this.checkInvalidateStatus(errorResponse),
            '',
            errorResponse.code
          );
          return of(null);
          break;
        case 404:
          this.errorPopup(
            errorResponse.errors || [],
            this.checkInvalidateStatus(errorResponse),
            '',
            errorResponse.code
          );
          return of(null);
          break;
        case 409:
          this.alertPopup(error?.message);
          return of(null);
          break;
        default:
          this.errorToast(error?.message);
          return of(null);
          break;
      }
    } catch (e) {
      return of(e);
    }
  }

  handleError4Loyalty(error: any): Observable<any> {
    try {
      const status = error.status;
      const errorResponse = error.error.error_code;
      this.errorToast(errorResponse);
      return of(null);
    } catch (e) {
      return of(e);
    }
  }

  async alertPopup(message: string = '') {
    const alert = await this.alertCtl.create({
      header: translations[environment.language].information,
      mode: 'ios',
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          id: 'confirm-button',
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    });
    await alert.present();
    const s = await alert.onDidDismiss();
    return s;
  }

  async errorPopup(
    errors: any,
    invalid_status: boolean = false,
    phase: string = '',
    code?: number
  ) {
    const genericErrorModal = await this.modalCtrl.create({
      component: GeneralErrorComponent,
      componentProps: {
        errors,
        invalid_status,
        phase,
        code,
      },
      mode: 'md',
      backdropDismiss: false,
    });

    genericErrorModal.present();
  }

  async orderErrorPopup(error: any) {
    const errorResponse = error.error.response;
    await this.errorPopup(
      errorResponse.errors || [],
      this.checkInvalidateStatus(errorResponse),
      '02',
      errorResponse.code
    );
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  async checkoutErrorPopup(error: any) {
    const errorResponse = error.error.response;
    await this.errorPopup(
      errorResponse.errors || [],
      this.checkInvalidateStatus(errorResponse),
      '03',
      errorResponse.code
    );
    this.router.navigateByUrl('/order', { replaceUrl: true });
  }

  async fastPayCheckoutErrorPopup(error: any) {
    const errorResponse = error.error.response;
    await this.errorPopup(
      errorResponse.errors || [],
      this.checkInvalidateStatus(errorResponse),
      '20',
      errorResponse.code
    );
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  async requestStartPaymentProcessErrorPopup(error: any) {
    const errorResponse = error.error.response;
    await this.errorPopup(
      errorResponse.errors || [],
      this.checkInvalidateStatus(errorResponse),
      '04',
      errorResponse.code
    );
    this.guestStore.guest$
      .pipe(
        filter((guest): guest is Guest => !!guest),
        take(1)
      )
      .subscribe((guest) => {
        if (guest.fast_pay_enabled && !guest.tip_section_enabled) {
          // fastPay TRUE and Tip FALSE
          this.checkoutStore.dispatchResetSplitPayment();
          this.checkoutStore.dispatchResetAnalyticProduts();
          this.router.navigateByUrl('/home', { replaceUrl: true });
        } else if (!guest.tip_section_enabled) {
          this.router.navigateByUrl('/order', { replaceUrl: true });
        } else {
          this.router.navigateByUrl('/order/checkout', { replaceUrl: true });
        }
        this.modalCtrl.dismiss();
      });
  }

  async requestPaymentCompletedProcessErrorPopup(error: any) {
    const errorResponse = error.error.response;
    await this.errorPopup(
      errorResponse.errors || [],
      this.checkInvalidateStatus(errorResponse),
      '06',
      errorResponse.code
    );
    this.checkoutStore.dispatchResetSplitPayment();
    this.checkoutStore.dispatchResetAnalyticProduts();
    this.router.navigateByUrl('/error', { replaceUrl: true });
  }

  checkInvalidateStatus(errorResponse: any) {
    let invalidateStatus = true;
    if (
      typeof errorResponse.invalidate_status !== 'undefined' &&
      errorResponse.invalidate_status !== null
    ) {
      invalidateStatus = !!errorResponse.invalidate_status;
    }
    return invalidateStatus;
  }
}
