import { NoConnectionDirective } from './shared/directives/no-connection.directive';
import { ConnectionErrorModalComponent } from './shared/components/connection-error-modal.component';
import { L4payFormModule } from './shared/l4pay-form/l4pay-form.module';
import { ErrorPage } from './views/error/error.page';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, mdTransitionAnimation } from '@ionic/angular';
import localeIt from '@angular/common/locales/it';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import { UIKitModule } from './shared/uikit.module';



registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    ErrorPage,
    ConnectionErrorModalComponent,
    NoConnectionDirective
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: mdTransitionAnimation // default: 'md' transition animation for page and modal
    }),
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    L4payFormModule,
    UIKitModule
  ],
  providers: [
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: IonicRouteStrategy
    // },
    // { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


