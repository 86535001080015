import { DecodePipe } from './pipes/decode.pipe';
import { TipCurrencyPipe } from './pipes/tip-currency.pipe';
import { L4PayDividerComponent } from './components/l4pay-divider.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Button4payComponent } from './components/button4pay/button4pay.component';
import { L4PayFooterComponent } from './components/l4pay-footer.component';
import { L4PayTotalComponent } from './components/l4pay-total.component';
import { ToolbarOrderDetailComponent } from './components/toolbar-order-detail/toolbar-order-detail.component';
import { L4PayButtonToggleModule } from './components/toggles/l4pay-button-toggle.module';
import { AmountPipe } from './pipes/amount.pipe';
import { DirectivesModule } from './directives.module';
import { CountryPipe } from './pipes/country.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { GeneralErrorComponent } from './components/general-error/general-error.component';

const components = [
  Button4payComponent,
  ToolbarOrderDetailComponent,
  L4PayFooterComponent,
  L4PayTotalComponent,
  L4PayDividerComponent,
  GeneralErrorComponent,
];

const pipes = [
  AmountPipe,
  CountryPipe,
  TipCurrencyPipe,
  LanguagePipe,
  DecodePipe,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    CommonModule,
    IonicModule,
    L4PayButtonToggleModule,
    DirectivesModule,
  ],
  exports: [...components, ...pipes, L4PayButtonToggleModule, DirectivesModule],
})
export class UIKitModule {}
