/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { SurveyRequest } from './../../models/survey';
import { Message } from './../../models/message';
import { PaymentCompleteResponse } from './../../models/payment_complete_response';
import {
  PaymentRequestFR,
  PaymentRequestIT,
} from 'src/app/models/payment_request';
import { PaymentResponse } from './../../models/payment_response';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PaymentIntent } from 'src/app/models/payment';

const surveyInitialValue = { survey_1: null, survey_2: null };

@Injectable({ providedIn: 'root' })
export class PaymentStore {
  public _survey = new BehaviorSubject<SurveyRequest>(surveyInitialValue);
  private paymentIntent = new BehaviorSubject<PaymentIntent | null>(null);
  private _paymentResponse = new BehaviorSubject<PaymentResponse | null>(null);
  // private _start = new BehaviorSubject<{method: string | null; start: boolean}>({ method:null, start:false });
  private _start = new BehaviorSubject<boolean>(false);
  private _startPayment = new BehaviorSubject<boolean>(false);
  private _completePayment = new BehaviorSubject<boolean>(false);
  private _paymentRequest = new BehaviorSubject<
    PaymentRequestFR | PaymentRequestIT | null
  >(null);
  private _message = new BehaviorSubject<Message | null>(null);
  private _attempt = new BehaviorSubject<number>(0);
  public retry = new BehaviorSubject<number>(0);
  private _paymentPage = new BehaviorSubject<boolean>(false);
  private _paymentCompleteResponse =
    new BehaviorSubject<PaymentCompleteResponse | null>(null);
  private _channel = new BehaviorSubject<boolean>(false);
  private _paymentCompletedChannelSubscribed = new BehaviorSubject<boolean>(
    false
  );
  private _cashSystemNoResponse = new BehaviorSubject<boolean>(false);
  private _abortProcess = new BehaviorSubject<boolean>(false);
  private _paymentFailMessage = new BehaviorSubject<string | null>(null);
  private _startGpayApplePay = new BehaviorSubject<boolean>(false);
  private _loadingPaymentCompleted = new BehaviorSubject<boolean>(false);
  private _startWalletPayment = new BehaviorSubject<boolean>(false);

  // startPaymentProcess$ = this._start.asObservable();
  start$ = this._start.asObservable();
  survey$ = this._survey.asObservable();
  paymentIntent$ = this.paymentIntent.asObservable();
  paymentPage$ = this._paymentPage.asObservable();
  paymentResponse$ = this._paymentResponse.asObservable();
  paymentComplete$ = this._paymentCompleteResponse.asObservable();
  startPayment$ = this._startPayment.asObservable();
  completePayment$ = this._completePayment.asObservable();
  paymentRequest$ = this._paymentRequest.asObservable();
  message$ = this._message.asObservable();
  channel$ = this._channel.asObservable();
  cashSystemNoResponse$ = this._cashSystemNoResponse.asObservable();
  abortProcess$ = this._abortProcess.asObservable();
  paymentFailMessage$ = this._paymentFailMessage.asObservable();
  paymentCompletedChannelSubscribed$ =
    this._paymentCompletedChannelSubscribed.asObservable();
  startGpayApplePay$ = this._startGpayApplePay.asObservable();
  loadingPaymentCompleted$ = this._loadingPaymentCompleted.asObservable();
  startWalletPayment$ = this._startWalletPayment.asObservable();

  constructor() {}

  getPayment(): PaymentRequestFR | PaymentRequestIT | null {
    const storage = localStorage.getItem('payment');
    return storage ? JSON.parse(storage) : null;
  }

  dispatchSetPaymentRequest(
    request: PaymentRequestFR | PaymentRequestIT
  ): void {
    this._paymentRequest.next(request);
  }

  dispatchSetPaymentResponse(response: PaymentResponse | null): void {
    this._paymentResponse.next(response);
  }

  dispatchSetPaymentCompleteResponse(
    response: PaymentCompleteResponse | null
  ): void {
    this._paymentCompleteResponse.next(response);
  }
  /**
   *
   * @param start = true - get payment url
   */
  dispatchGetPaymentUrl(method: string | null, start: boolean): void {
    console.log('get payment url of PayXpert...');
    this._attempt.next(0);
    // this._start.next({ method, start });
  }

  dispatchStartPaymentProcess(): void {
    this._start.next(true);
  }

  dispatchStopPaymentProcess(): void {
    // this._start.next({ method: null, start: false });
    this._start.next(false);
  }

  /**
   * Payment with Stripe Elements
   */
  dispatchSetPaymentIntent(obj: PaymentIntent): void {
    this.paymentIntent.next(obj);
  }

  dispatchPaymentLoading(start: boolean): void {
    // this._start.next({ method: null, start });
  }

  backToPaymentPage(): void {
    this._paymentPage.next(true);
  }

  pristinePaymentPage(): void {
    this._paymentPage.next(false);
  }

  dispatchStartPayment(start: boolean): void {
    this._startPayment.next(start);
  }

  dispatchCompletePayment(): void {
    this._completePayment.next(true);
  }

  dispatchMessage(message: Message | null): void {
    this._message.next(message);
  }

  dispatchSubscribeChannel(sub: boolean): void {
    this._channel.next(sub);
  }

  dispatchPaymentSubscribeChannel(sub: boolean): void {
    this._paymentCompletedChannelSubscribed.next(sub);
  }

  dispatchCashSytemNoResponse(res: boolean): void {
    this._cashSystemNoResponse.next(res);
  }

  dispatchAbortProcess(res: boolean): void {
    this._abortProcess.next(res);
  }

  dispatchSurveyRequest(survey: SurveyRequest): void {
    this._survey.next(survey);
  }

  dispatchPaymentFailMessage(message: string | null): void {
    this._paymentFailMessage.next(message);
  }

  getPayxpertStatus(): any | null {
    const payxpertStatus = localStorage.getItem('payxpertStatus');
    return payxpertStatus ? JSON.parse(payxpertStatus) : null;
  }

  dispatchStartGpayApplePay(start: boolean): void {
    this._startGpayApplePay.next(start);
  }

  dispatchLoadingPaymentCompleted(value: boolean) {
    this._loadingPaymentCompleted.next(value);
  }

  dispatchStartWalletPayment(value: boolean) {
    this._startWalletPayment.next(value);
  }

  reset(): void {
    // localStorage.removeItem('payment');
    this.dispatchSetPaymentResponse(null);
    this._completePayment.next(false);
    this.paymentIntent.next(null);
    this._channel.next(false);
    this.pristinePaymentPage();
  }

  clear(): void {
    localStorage.removeItem('receipt');
    localStorage.removeItem('guest');
    localStorage.removeItem('payxpertStatus');
  }

  clean(): void {
    this.dispatchStopPaymentProcess();
    this.dispatchStartPayment(false);
    this.dispatchStartGpayApplePay(false);
    this.dispatchSubscribeChannel(false);
    this.dispatchPaymentSubscribeChannel(false);
    this._completePayment.next(false);
    this._paymentRequest.next(null);
    this.pristinePaymentPage();
    this.dispatchSetPaymentCompleteResponse(null);
    this.dispatchSurveyRequest(surveyInitialValue);
    this.dispatchStartWalletPayment(false);
    localStorage.removeItem('payment');
    localStorage.removeItem('payxpertStatus');
  }
}
