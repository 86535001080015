import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IonInput } from '@ionic/angular';

@Directive({
  selector: '[focusFirstInvalidField]',
})
export class FocusFirstInvalidFieldDirective implements OnChanges {
  field: { touched: boolean } = { touched: false };
  constructor(private el: ElementRef) {}

  @Input() set focusFirstInvalidField(touched: { touched: boolean }) {
    this.field = touched;
    // console.log(this.field);
  }

  /**
   *
   * @param changes it actives after every changedetection and it will focus on first invalid input
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.focusFirstInvalidField.currentValue.touched) {
      // focus on first invalid input selected
      const invalidControl: IonInput =
        this.el.nativeElement.querySelector('.ng-invalid');
      if (invalidControl) {
        invalidControl
          .getInputElement()
          .then((inputElement) => inputElement.focus());
      }
    }
  }
}
