import { L4PayLabelComponent } from './l4pay-label.component';
import { L4PayFormFieldComponent } from './l4pay-form-field.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { L4PayErrorComponent } from './l4pay-error.component';

@NgModule({
  declarations: [L4PayFormFieldComponent, L4PayLabelComponent, L4PayErrorComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [L4PayFormFieldComponent, L4PayLabelComponent, L4PayErrorComponent]
})
export class L4payFormModule { }
